<template>
  <page>
    <page-title class="px-6 pt-4">
      <template #icon>
        <ic-cog :size="24" />
      </template>
      <template #default>Account Details</template>
    </page-title>
    <div class="pt-6 border-b">
      <page-tabs
        :selected-tab="selectedTab"
        :tabs="tabs"
        @select-tab="tab => selectTab(tab)"
      />
    </div>
    <page-section
      class="overflow-y-auto h-screen base-scrollbar"
      bottom-border
    >
      <transition
        :name="direction"
        mode="out-in"
      >
        <component
          :is="selectedTab"
          v-if="siteDetails"
          class="mb-80"
          :site="siteDetails"
          @site-updated="loadSite"
        />
      </transition>
    </page-section>
  </page>
</template>

<script>
import IcCog from 'vue-material-design-icons/CogOutline'
import SiteInfoForm from '@/components/settings/SiteInfoForm'
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'
import PageTabs from '@/components/shared/PageTabs'
import SiteAndBusinessForm from '@/components/settings/SiteAndBusinessForm'
import FeedTheAI from '@/components/settings/FeedTheAI'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SiteSettings',
  components: { Page, PageSection, SiteInfoForm, IcCog, PageTabs, SiteAndBusinessForm, FeedTheAI },
  props: ['site'],
  data() {
    return {
      tabs: [
        { label: 'General Info', value: 'SiteInfoForm', index: 0 },
        { label: 'Account & Business', value: 'SiteAndBusinessForm', index: 1 },
        { label: 'Feed the AI', value: 'FeedTheAI', index: 2 },
      ],
      selectedTab: 'SiteInfoForm',
      direction: '', // For transition direction
    }
  },
  computed: {
    ...mapState('site', ['selectedSite', 'siteDetails']),
  },
  watch: {
    async selectedSite(newVal, oldVal) {
      if (newVal && newVal.value !== oldVal?.value) {
        this.pageLoading = true
        await this.fetchSiteDetails(newVal.value)
        this.pageLoading = false
      }
    },
  },
  async mounted() {
    this.selectedTab = this.$route.query.tab || 'SiteInfoForm'
    if (this.selectedSite) {
      await this.fetchSiteDetails(this.selectedSite.value)
    }
  },
  methods: {
    ...mapActions('site', ['fetchSiteDetails']),
    loadSite() {
      this.fetchSiteDetails(this.selectedSite.value)
    },
    selectTab(tab) {
      const newIndex = this.tabs.findIndex(t => t.value === tab)
      const currentIndex = this.tabs.findIndex(t => t.value === this.selectedTab)
      this.direction = newIndex > currentIndex ? 'tab-transition-left' : 'tab-transition-right'

      // Update selected tab after transition direction is set
      this.selectedTab = tab
    },
  },
}
</script>
