<template>
  <div
    class="rounded text-black text-center w-5 h-5 flex items-center justify-center"
    :class="intent.value"
  >
    <p>{{ intent.label }}</p>
  </div>
</template>

<script>
export default {
  name: 'IntentType',
  props: {
    intentType: {
      type: String,
      required: true,
    },
  },
  computed: {
    intent() {
      switch (this.intentType) {
        case 'commercial':
          return { label: 'C', value: 'bg-bb-commercial' }
        case 'informational':
          return { label: 'I', value: 'bg-bb-informational' }
        case 'navigational':
          return { label: 'N', value: 'bg-bb-navigational' }
        case 'transactional':
          return { label: 'T', value: 'bg-bb-transactional' }
        default:
          return { label: '-', value: 'bg-bb-disabled-gray' }
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
