// eslint-disable-next-line no-undef
const themeConfig = require('./theme.config')

// eslint-disable-next-line no-undef
module.exports = {
  future: {
    // removeDeprecatedGapUtilities: true,
    // purgeLayersByDefault: true,
  },
  purge: ['./public/**/*.html', './src/**/*.vue'],
  theme: {
    container: false,
    extend: {
      maxWidth: {
        xxs: '256px',
        content: '1200px',
        10: '40px',
        20: '80px',
        30: '120px',
        35: '140px',
        40: '160px',
        42: '168px',
        44.5: '178px',
        46: '180px',
        60: '240px',
        64: '256px',
        75: '300px',
        80: '320px',
        90: '360px',
        100: '400px',
        105: '420px',
        110: '440px',
        120: '480px',
        130: '552px',
        150: '600px',
        163: '650px',
        172: '688px',
        173: '691px',
        180: '720px',
        500: '500px',
        200: '800px',
        215: '860px',
      },
      minWidth: {
        12: '48px',
        32: '128px',
        35: '140px',
        37: '148px',
        38: '149px',
        40: '160px',
        50: '200px',
        60: '240px',
        90: '360px',
        96: '384px',
        xxs: '256px',
        100: '400px',
        140: '560px',
        150: '600px',
        155: '620px',
        180: '720px',
        215: '860px',
        200: '800px',
        content: '1200px',
      },
      maxHeight: {
        subMenu: '400px',
        0: '0px',
        18: '72px',
        20: '80px',
        27: '108px',
        40: '160px',
        48: '192px',
        50: '200px',
        55: '220px',
        56: '224px',
        68: '272px',
        80: '320px',
        82: '328px',
        90: '360px',
        92: '368px',
        94: '376px',
        96: '384px',
        98: '392px',
        100: '400px',
        120: '480px',
        140: '560px',
        150: '600px',
        155: '620px',
        170: '680px',
        200: '800px',
      },
      minHeight: {
        subMenu: '400px',
        0: '0px',
        12: '25px',
        18: '72px',
        20: '80px',
        25: '100px',
        40: '160px',
        48: '192px',
        50: '200px',
        68: '272px',
        80: '320px',
        82: '328px',
        100: '400px',
        106: '424px',
        120: '480px',
        500: '500px',
      },
      colors: themeConfig.colors,
      boxShadow: {
        'drop-8': '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        'bb-shadow': '0px 2px 14px 0px rgba(136, 152, 170, 0.15)',
        'drop-12': '0px 5px 22px 4px rgba(136, 152, 170, 0.12)',
        'drop-14': '0px 5px 22px 4px rgba(136, 152, 170, 0.14)',
      },
      lineHeight: {
        12: '3rem',
      },
      transitionProperty: {
        height: 'height',
      },
      gap: {
        2.5: '10px',
        13: '52px',
        15: '60px',
        16: '64px',
        200: '200px',
        150: '150px',
        100: '100px',
      },
      height: {
        0.5: '0.125rem',
        7: '28px',
        9.5: '38px',
        9: '36px',
        11: '44px',
        13: '52px',
        14: '56px',
        16: '64px',
        18: '72px',
        19: '76px',
        20: '80px',
        22: '88px',
        25: '100px',
        27: '108px',
        30: '120px',
        32: '128px',
        35: '140px',
        40: '160px',
        48: '192px',
        50: '200px',
        55: '220px',
        67: '268px',
        100: '400px',
        106: '424px',
        120: '480px',
        140: '560px',
        155: '620px',
        160: '640px',
        300: '300px',
        500: '500px',
        '50px': '50px',
        '70vh': '70vh',
      },
      width: {
        9: '36px',
        11: '44px',
        13: '52px',
        14: '56px',
        15: '60px',
        18: '72px',
        25: '100px',
        30: '120px',
        34: '136px',
        36: '144px',
        37: '148px',
        38: '152px',
        42: '168px',
        46: '184px',
        48: '192px',
        50: '200px',
        58: '232px',
        64: '256px',
        70: '280px',
        80: '320px',
        82: '328px',
        87: '348px',
        90: '360px',
        96: '384px',
        100: '400px',
        110: '440px',
        115: '460px',
        116: '464px',
        120: '480px',
        150: '600px',
        172: '688px',
        200: '800px',
        300: '300px',
        500: '500px',
        390: '390px',
        '50px': '50px',
      },
      padding: {
        0.5: '2px',
        1.5: '6px',
        3.5: '14px',
        7: '28px',
        9: '36px',
      },
      margin: {
        0.5: '2px',
        7: '28px',
        13.5: '54px',
        15.5: '62px',
        28: '112px',
        42: '108px',
        60: '240px',
        80: '320px',
      },
      inset: {
        2: '8px',
        6: '24px',
        7: '28px',
        10: '40px',
        14: '56px',
        20: '80px',
        24: '96px',
        25: '100px',
        30: '120px',
        40: '160px',
        42: '168px',
        44: '172px',
        49: '196px',
        300: '300px',
      },
      keyframes: {
        ping: {
          '0%, 100%': {
            transform: 'scale(1)', // Initial and final state
            opacity: '1',
          },
          '50%': {
            transform: 'scale(1.05)', // Midway point
            opacity: '1',
          },
        },
      },
      rotate: {
        135: '135deg',
      },
      backgroundImage: {
        'bb-bg-gradient': 'linear-gradient(182.62deg, #FFFFFF -3.9%, #F9FAFB 88.53%)',
      },
      borderRadius: {
        custom: '5px',
      },
    },
    screens: {
      xs: '375px',
      s: '480px',
      sm: '667px',
      md: '769px',
      lg: '1024px',
      xl: '1200px',
      bbxl: '1440px',
    },
    translate: {
      6: '24px',
      7: '28px',
    },
  },
  variants: {
    extend: {
      scale: ['active', 'focus'],
    },
    backgroundColor: ['odd', 'even', 'hover', 'active'],
    textColor: ['hover', 'active'],
    container: [],
  },
  plugins: [],
}
