<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.2 15.1926L8 10.3846L11.2 13.5846L18 5.93659L16.872 4.80859L11.2 11.1846L8 7.98459L2 13.9926L3.2 15.1926Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcOneLineChart',
  props: {
    size: {
      type: Number,
      default: 20,
    },
  },
}
</script>

<style scoped lang="scss"></style>
