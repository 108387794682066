<template>
  <div
    class="flex items-center justify-between"
    :class="{ 'pointer-events-none': isChartLoading }"
  >
    <!--DONUT CHART-->
    <div>
      <svg :id="chartId"></svg>
    </div>

    <!--LEGENDS-->
    <div class="space-y-4 min-w-50 max-w-full">
      <div
        v-for="(legend, index) in doughnutChartData"
        :key="index"
        class="cursor-pointer text-xs w-full h-4"
        @click="selectLegend(legend)"
        @mouseenter="hover(legend)"
        @mouseleave="removeHover(legend)"
      >
        <div
          class="flex items-center justify-between"
          :class="{
            'font-bold leading-none ': legend.label === hoveredLegend,
            'text-bb-disabled-gray': selectedLegends.includes(legend.label),
          }"
        >
          <div class="flex gap-1 items-center">
            <div
              class="w-2 h-2 rounded-full mr-0.5"
              :style="{ backgroundColor: legendColors[index] }"
            ></div>
            <p
              :class="{
                'text-bb-brand-purple': legend.isOwn && !selectedLegends.includes(legend.label),
              }"
              class="max-w-30 truncate duration-200 ease-linear capitalize"
            >
              {{ legend.label }}
            </p>
            <span
              v-if="legend.isOwn"
              class="text-bb-neutral-gray"
              >(you)</span
            >
          </div>
          <p class="pl-5">{{ legend.percentage }}%</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createDonutSvg } from '@/views/site/search/competitor_monitoring/overview/doughnut-chart'
import * as d3 from 'd3'

const DEFAULT_LEGEND_COLORS = ['#6366FA', '#84FAE4', '#ACACFD', '#A3CDFE', '#FFA776', '#FFDB76']

export default {
  name: 'DoughnutChart',

  props: {
    chartData: {
      type: Array,
      required: true,
    },
    chartId: {
      type: String,
      required: true,
    },
    legendColors: {
      type: Array,
      default: () => DEFAULT_LEGEND_COLORS,
    },
    innerRadius: {
      type: Number,
      default: 60,
    },
    outerRadius: {
      type: Number,
      default: 110,
    },
    diameter: {
      type: Number,
      default: 240,
    },
  },
  data() {
    return {
      selectedLegends: [],
      hoveredLegend: null,
      isChartLoading: false,
    }
  },
  computed: {
    sizes() {
      return {
        innerRadius: this.innerRadius,
        outerRadius: this.outerRadius,
        height: this.diameter,
        width: this.diameter,
      }
    },
    doughnutChartData() {
      const data = structuredClone(this.chartData)

      // move the own website to the top of the list
      const index = data.findIndex(item => item?.isOwn)

      if (index > -1) {
        const element = data.splice(index, 1)[0]
        data.unshift(element)
      }

      if (data.length > 6) {
        const others = data.splice(5)
        const sum = others.reduce((accumulator, current) => accumulator + current.percentage, 0)

        data.push({
          label: 'Others',
          percentage: sum.toFixed(2),
          isOwn: false,
        })
      }
      return data.map((item, index) => {
        return {
          ...item,
          value: 3 > item.percentage > 0 ? 3 : item.percentage,
          color: this.legendColors[index],
        }
      })
    },
    usableData() {
      return this.doughnutChartData.filter(item => !this.selectedLegends.includes(item.label))
    },
  },
  watch: {
    chartData() {
      this.createChart()
    },
  },
  mounted() {
    this.createChart()
  },
  methods: {
    createChart() {
      this.isChartLoading = true

      const chartData = {
        chartId: this.chartId,
        data: this.usableData,
        sizes: this.sizes,
      }
      createDonutSvg(chartData)
      setTimeout(() => {
        this.isChartLoading = false
      }, 2200)
    },
    selectLegend(legend) {
      this.removeHover(legend)
      this.isChartLoading = true
      // Remove if it exists
      if (this.selectedLegends.includes(legend.label)) {
        this.selectedLegends = this.selectedLegends.filter(selectedLegend => selectedLegend !== legend.label)
      } else {
        // Add if it is not yet in the list
        this.selectedLegends.push(legend.label)
      }

      this.createChart()
      setTimeout(() => {
        this.isChartLoading = false
      }, 2200)
      this.$emit('update-selected-legend-count', this.selectedLegends.length)
    },
    hover(legend) {
      if (this.isChartLoading) return
      this.hoveredLegend = legend.label

      d3.select(`#arc-${legend.label}-${this.chartId}`)
        .transition()
        .duration(100)
        .attr('transform', () => `scale(1.05)`)
      d3.select(`#label-${legend.label}-${this.chartId}`).transition().duration(300).style('opacity', 1)
      d3.select(`#circle-${legend.label}-${this.chartId}`).transition().duration(300).style('opacity', 1) // Show label
    },
    removeHover(legend) {
      if (this.isChartLoading) return
      d3.select(`#arc-${legend.label}-${this.chartId}`)
        .transition()
        .duration(100)
        .attr('transform', () => `scale(1)`)
      d3.select(`#label-${legend.label}-${this.chartId}`).transition().duration(300).style('opacity', 0)
      d3.select(`#circle-${legend.label}-${this.chartId}`).transition().duration(300).style('opacity', 0)

      this.hoveredLegend = null
    },
    resetChartData() {
      this.selectedLegends = []
      this.createChart()
    },
  },
}
</script>

<style scoped lang="scss"></style>
