import { getErrorStatus } from '@/utils/util'
import * as Sentry from '@sentry/vue'

const TYPE = {
  LOAD_CMS_SITE: 'LOAD_CMS_SITE',
  LOAD_ORGANIZATION_CREDIT: 'LOAD_ORGANIZATION_CREDIT',
  LOAD_MONITORING_GROUPS: 'LOAD_MONITORING_GROUPS',
  LOAD_SELECTED_MONITORING_GROUP: 'LOAD_SELECTED_MONITORING_GROUP',
  LOAD_INSIGHTS: 'LOAD_INSIGHTS',
  LOAD_COMPETITOR_SUMMARY: 'LOAD_COMPETITOR_SUMMARY',
  LOAD_SAVINGS_INSIGHTS: 'LOAD_SAVINGS_INSIGHTS',
  LOAD_MOST_FREQUENT_RANK: 'LOAD_MOST_FREQUENT_RANK',

  LOAD_INFRINGEMENT_SUMMARY: 'LOAD_INFRINGEMENT_SUMMARY',
  LOAD_ALL_INFRINGEMENTS: 'LOAD_ALL_INFRINGEMENTS',
  LOAD_INFRINGEMENT_DETAILS: 'LOAD_INFRINGEMENT_DETAILS',
  LOAD_WHITELIST: 'LOAD_WHITELIST',

  LOAD_ALL_COMPETITORS: 'LOAD_ALL_COMPETITORS',

  SET_DATE_RANGE: 'SET_DATE_RANGE',
  CLEAR: 'CLEAR',
}

const SELECTED_MONITORING_GROUP = 'selected_monitoring_group'

const today = new Date()
const last30Days = new Date()
last30Days.setDate(today.getDate() - 30)
const DEFAULT_DATES = [last30Days, today]

export default {
  namespaced: true,
  state: {
    // Common
    dateRange: DEFAULT_DATES,
    selectedMonitoringGroup: JSON.parse(localStorage.getItem(SELECTED_MONITORING_GROUP)) || null,
    cmsSite: null,
    organizationCredit: null,
    monitoringGroups: [],

    // Overview
    insights: null,
    competitorSummary: null,
    savingsInsights: null,
    mostFrequentRank: {},

    // Infringements
    infringementSummary: null,
    allInfringements: [],
    infringementDetails: {},
    whitelist: [],

    // Competitors
    allCompetitors: [],
  },
  getters: {
    top10CompetitorsByImpressionShare(state) {
      if (!state.insights) return []
      const sortedInsights = structuredClone(state.insights).sort((a, b) => b.impressionShare - a.impressionShare)

      return sortedInsights.slice(0, 10)
    },
    competitorsByAbsoluteTopOfPage(state) {
      if (!state.insights) return []
      return structuredClone(state.insights).sort((a, b) => b.absoluteTopOfPage - a.absoluteTopOfPage)
    },
  },
  actions: {
    setDateRange({ commit }, data) {
      commit(TYPE.SET_DATE_RANGE, data)
    },
    resetDateRange({ commit }) {
      commit(TYPE.SET_DATE_RANGE, DEFAULT_DATES)
    },
    async loadCmsSite({ dispatch, commit }, siteId) {
      try {
        const { data: site } = await axios.get(`/cms/site/${siteId}`)
        commit(TYPE.LOAD_CMS_SITE, site)
        return site
      } catch (e) {
        // TODO: Set error state
        if (getErrorStatus(e) !== 404) {
          Sentry.captureException(e)
          await dispatch(
            'toast/loadToast',
            {
              title: 'Error',
              message: 'Failed to get site. Please try again later.',
              type: 'error',
            },
            { root: true },
          )
        }
        return null
      }
    },
    async loadOrganizationCredit({ dispatch, commit }, organizationId) {
      try {
        const { data: credit } = await axios.get(`/common/organization/${organizationId}/cms-credit/usage`)
        commit(TYPE.LOAD_ORGANIZATION_CREDIT, credit)
        return credit
      } catch (e) {
        if (getErrorStatus(e) !== 404) {
          Sentry.captureException(e)
          await dispatch(
            'toast/loadToast',
            {
              title: 'Error',
              message: 'Failed to get organization credit limit. Please try again later.',
              type: 'error',
            },
            { root: true },
          )
        }
        return null
      }
    },
    async loadMonitoringGroups({ dispatch, commit }, siteId) {
      try {
        const { data: monitoringGroups } = await axios.get(`/cms/site/${siteId}/monitoring-job/v3`)
        commit(TYPE.LOAD_MONITORING_GROUPS, monitoringGroups)
        return monitoringGroups
      } catch (error) {
        // TODO: Set error state
        await dispatch(
          'toast/loadToast',
          {
            title: 'Error',
            message: 'Failed to get monitoring groups. Please try again later.',
            type: 'error',
          },
          { root: true },
        )
      }
    },
    async loadCMSInsights(
      { dispatch, commit, state },
      { siteId, monitoringGroupId, keywords, geoLocations, startDate, endDate, fullReload },
    ) {
      if (!fullReload && state.insights !== null) {
        return
      }
      try {
        const { data: insights } = await axios.post(
          `/cms/site/${siteId}/monitoring-job/${monitoringGroupId}/insights/my-summary`,
          {
            startDate,
            endDate,
            geoLocations,
            keywords,
          },
        )
        commit(TYPE.LOAD_INSIGHTS, insights)
        return insights
      } catch (error) {
        // TODO: Set error state
        await dispatch(
          'toast/loadToast',
          {
            title: 'Error',
            message: 'Failed to get monitoring groups. Please try again later.',
            type: 'error',
          },
          { root: true },
        )
      }
    },
    async loadCompetitorSummary(
      { commit, dispatch, state },
      { siteId, monitoringGroupId, startDate, endDate, fullReload },
    ) {
      if (!fullReload && state.competitorSummary !== null) {
        return
      }
      try {
        const { data: summary } = await axios.get(
          `/cms/site/${siteId}/monitoring-job/${monitoringGroupId}/competitor/insights?startDate=${startDate}&endDate=${endDate}`,
        )

        commit(TYPE.LOAD_COMPETITOR_SUMMARY, summary)
        return summary
      } catch (error) {
        // TODO: Set error state
        await dispatch(
          'toast/loadToast',
          {
            title: 'Error',
            message: 'Failed to get monitoring groups. Please try again later.',
            type: 'error',
          },
          { root: true },
        )
      }
    },
    async loadSavingsInsights({ commit, dispatch, state }, { siteId, startDate, endDate, fullReload }) {
      if (!fullReload && state.savingsInsights !== null) {
        return
      }
      try {
        const { data: savingsInsights } = await axios.get(
          `/cms/site/${siteId}/savings?start_date=${startDate}&end_date=${endDate}`,
        )

        commit(TYPE.LOAD_SAVINGS_INSIGHTS, savingsInsights)
        return savingsInsights
      } catch (error) {
        // TODO: Set error state
        await dispatch(
          'toast/loadToast',
          {
            title: 'Error',
            message: 'Failed to get smart savings insights. Please try again later.',
            type: 'error',
          },
          { root: true },
        )
      }
    },
    async loadMostFrequentRank(
      { commit, dispatch, state },
      { siteId, monitoringGroupId, startDate, endDate, dataPoints, fullReload },
    ) {
      if (!fullReload && state.mostFrequentRank !== null) {
        return
      }
      try {
        const { data: mostFrequentRank } = await axios.post(
          `/cms/site/${siteId}/monitoring-job/${monitoringGroupId}/insights/average-ad-position?dataPoints=${dataPoints}`,
          {
            startDate: startDate,
            endDate: endDate,
          },
        )

        commit(TYPE.LOAD_MOST_FREQUENT_RANK, mostFrequentRank)
        return mostFrequentRank
      } catch (error) {
        // TODO: Set error state
        await dispatch(
          'toast/loadToast',
          {
            title: 'Error',
            message: 'Failed to get smart savings insights. Please try again later.',
            type: 'error',
          },
          { root: true },
        )
      }
    },

    /*
     * Infringements
     */
    async loadInfringementSummary(
      { commit, dispatch, state },
      { siteId, monitoringGroupId, startDate, endDate, fullReload },
    ) {
      if (!fullReload && state.infringementSummary !== null) {
        return
      }
      try {
        const { data: infringementSummary } = await axios.get(
          `/cms/site/${siteId}/monitoring-job/${monitoringGroupId}/infringement/summary?startDate=${startDate}&endDate=${endDate}`,
        )

        commit(TYPE.LOAD_INFRINGEMENT_SUMMARY, infringementSummary)
        return infringementSummary
      } catch (error) {
        // TODO: Set error state
        await dispatch(
          'toast/loadToast',
          {
            title: 'Error',
            message: 'Failed to get smart savings insights. Please try again later.',
            type: 'error',
          },
          { root: true },
        )
      }
    },
    async loadAllInfringements(
      { commit, dispatch, state },
      { siteId, monitoringGroupId, startDate, endDate, fullReload },
    ) {
      if (!fullReload && state.allInfringements !== null) {
        return
      }
      try {
        const { data: allInfringements } = await axios.get(
          `/cms/site/${siteId}/monitoring-job/${monitoringGroupId}/infringement?startDate=${startDate}&endDate=${endDate}`,
        )

        commit(TYPE.LOAD_ALL_INFRINGEMENTS, allInfringements)
        return allInfringements
      } catch (error) {
        // TODO: Set error state
        await dispatch(
          'toast/loadToast',
          {
            title: 'Error',
            message: 'Failed to get all infringements. Please try again later.',
            type: 'error',
          },
          { root: true },
        )
      }
    },
    async loadInfringementDetails(
      { commit, dispatch },
      { siteId, monitoringGroupId, startDate, endDate, competitorName },
    ) {
      try {
        const { data: infringementDetails } = await axios.post(
          `/cms/site/${siteId}/monitoring-job/${monitoringGroupId}/infringement/more/v2`,
          {
            startDate,
            endDate,
            competitorName,
          },
        )

        commit(TYPE.LOAD_INFRINGEMENT_DETAILS, { competitorName, infringementDetails })
        return { competitorName, infringementDetails }
      } catch (error) {
        // TODO: Set error state
        await dispatch(
          'toast/loadToast',
          {
            title: 'Error',
            message: 'Failed to get infringement details. Please try again later.',
            type: 'error',
          },
          { root: true },
        )
      }
    },
    async loadWhitelist({ commit, dispatch, state }, { siteId, monitoringGroupId, fullReload }) {
      if (!fullReload && state.whitelist !== null) {
        return
      }
      try {
        const { data: whitelist } = await axios.get(
          `/cms/site/${siteId}/monitoring-job/${monitoringGroupId}/infringement/whitelist`,
        )

        commit(TYPE.LOAD_WHITELIST, whitelist)
        return whitelist
      } catch (error) {
        // TODO: Set error state
        await dispatch(
          'toast/loadToast',
          {
            title: 'Error',
            message: 'Failed to get whitelist. Please try again later.',
            type: 'error',
          },
          { root: true },
        )
      }
    },

    /*
     * Competitors
     */
    async loadAllCompetitors(
      { commit, dispatch, state },
      { siteId, monitoringGroupId, startDate, endDate, fullReload },
    ) {
      if (!fullReload && state.whitelist !== null) {
        return
      }
      try {
        const { data: allCompetitors } = await axios.get(
          `/cms/site/${siteId}/monitoring-job/${monitoringGroupId}/competitor/all?startDate=${startDate}&endDate=${endDate}`,
        )

        commit(TYPE.LOAD_ALL_COMPETITORS, allCompetitors)
        return allCompetitors
      } catch (error) {
        // TODO: Set error state
        await dispatch(
          'toast/loadToast',
          {
            title: 'Error',
            message: 'Failed to get all competitors. Please try again later.',
            type: 'error',
          },
          { root: true },
        )
      }
    },

    loadSelectedMonitoringGroup({ commit }, data) {
      commit(TYPE.LOAD_SELECTED_MONITORING_GROUP, data)
    },
    clear({ commit }, clearAll = false) {
      commit(TYPE.CLEAR, clearAll)
    },
  },
  mutations: {
    [TYPE.LOAD_CMS_SITE](state, data) {
      if (!data) {
        state.cmsSite = null
        return
      }
      state.cmsSite = data
    },
    [TYPE.LOAD_ORGANIZATION_CREDIT](state, data) {
      if (!data) {
        state.organizationCredit = null
        return
      }
      state.organizationCredit = data
    },
    [TYPE.LOAD_MONITORING_GROUPS](state, data) {
      if (!data) {
        state.monitoringGroups = []
        return
      }
      state.monitoringGroups = data
    },
    [TYPE.LOAD_SELECTED_MONITORING_GROUP](state, data) {
      if (!data) {
        state.selectedMonitoringGroup = null
        localStorage.removeItem(SELECTED_MONITORING_GROUP)
        return
      }
      state.selectedMonitoringGroup = data
      localStorage.setItem(SELECTED_MONITORING_GROUP, JSON.stringify(data))
    },
    [TYPE.LOAD_INSIGHTS](state, data) {
      if (!data) {
        state.insights = null
        return
      }
      state.insights = data
    },
    [TYPE.LOAD_COMPETITOR_SUMMARY](state, data) {
      if (!data) {
        state.competitorSummary = null
        return
      }
      state.competitorSummary = data
    },
    [TYPE.LOAD_SAVINGS_INSIGHTS](state, data) {
      if (!data) {
        state.savingsInsights = null
        return
      }
      state.savingsInsights = data
    },
    [TYPE.LOAD_INFRINGEMENT_SUMMARY](state, data) {
      if (!data) {
        state.infringementSummary = null
        return
      }
      state.infringementSummary = data
    },
    [TYPE.LOAD_MOST_FREQUENT_RANK](state, data) {
      if (!data) {
        state.mostFrequentRank = {}
        return
      }
      state.mostFrequentRank = data
    },
    [TYPE.LOAD_ALL_INFRINGEMENTS](state, data) {
      if (!data) {
        state.allInfringements = []
        return
      }
      state.allInfringements = data ? data : []
    },
    [TYPE.LOAD_INFRINGEMENT_DETAILS](state, { competitorName, infringementDetails }) {
      if (!infringementDetails || !competitorName) return

      state.infringementDetails = {
        ...state.infringementDetails,
        [competitorName]: infringementDetails,
      }
    },
    [TYPE.LOAD_WHITELIST](state, data) {
      if (!data) {
        state.whitelist = []
        return
      }
      state.whitelist = data
    },
    [TYPE.LOAD_ALL_COMPETITORS](state, data) {
      if (!data) {
        state.allCompetitors = []
        return
      }
      state.allCompetitors = data
    },

    [TYPE.SET_DATE_RANGE](state, data) {
      if (!data) {
        state.dateRange = DEFAULT_DATES
        return
      }
      state.dateRange = data
    },
    [TYPE.CLEAR](state, clearAll) {
      if (clearAll) {
        state.cmsSite = null
        state.organizationCredit = null
        state.monitoringGroups = []
      }

      state.selectedMonitoringGroup = null
      state.dateRange = DEFAULT_DATES

      // Overview
      state.insights = null
      state.competitorSummary = null
      state.savingsInsights = null
      state.mostFrequentRank = {}

      // Infringements
      state.infringementSummary = null
      state.allInfringements = []
      state.infringementDetails = {}
      state.whitelist = []

      // Competitors
      state.allCompetitors = []

      localStorage.removeItem(SELECTED_MONITORING_GROUP)
    },
  },
}
