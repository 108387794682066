<template>
  <tbody>
    <tr v-if="tableRows.length === 0">
      <td colspan="4">
        <div class="mx-auto my-20 flex flex-col items-center justify-center">
          <p class="text-xl my-2">No expressions found...</p>
        </div>
      </td>
    </tr>
    <tr
      v-for="(item, index) in tableRows"
      v-else
      :key="item.id"
      class="whitespace-no-wrap font-normal w-full text-sm"
      :class="[
        hoveredRow === index ? 'bg-bb-background-purple-0' : 'bg-white',
        index % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
      ]"
      @mouseenter="hoveredRow = index"
      @mouseleave="hoveredRow = null"
    >
      <td
        class="px-4 py-2 whitespace-no-wrap sticky left-0 w-50"
        :class="[
          hoveredRow === index ? 'bg-bb-background-purple-0' : 'bg-white',
          index % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
        ]"
      >
        {{ item.expression }}
      </td>
      <td class="px-4 text-right">
        <div class="flex items-center justify-end pr-4">
          <ic-delete
            v-show="hoveredRow === index"
            :size="20"
            class="cursor-pointer"
            :class="
              touchedExpressions.includes(item.id)
                ? 'text-bb-disabled-buttons pointer-events-none'
                : 'text-bb-disabled-gray'
            "
            @click="removeExpression(item.id)"
          />
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import IcDelete from 'vue-material-design-icons/DeleteOutline'

export default {
  name: 'ExclusionRows',
  components: { IcDelete },
  props: {
    tableRows: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    touchedExpressions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      hoveredRow: null,
      expressionInput: '',
      hasDuplicateKeyword: false,
      maximumLengthError: false,
      minimumLengthError: false,
    }
  },
  methods: {
    addExpression() {
      const expression = this.expressionInput.trim()
      if (expression === '') {
        this.clearInput()
        return
      }

      if (expression.length < 2) {
        this.minimumLengthError = true
        return
      }
      if (expression.length > 22) {
        this.maximumLengthError = true
        return
      }
      this.tableRows.find(item => item.expression === expression)
        ? (this.hasDuplicateKeyword = true)
        : (this.hasDuplicateKeyword = false)
      if (this.hasDuplicateKeyword) return

      this.$emit('add-expression', expression)
      this.clearInput()
    },
    removeExpression(id) {
      if (this.touchedExpressions.includes(id)) return
      this.$emit('remove-expression', id)
    },
    updateExpression(id, isCompetitor) {
      if (this.touchedExpressions.includes(id)) return
      this.$emit('update-expression', id, isCompetitor)
    },
    clearInput() {
      this.expressionInput = ''
      this.hasDuplicateKeyword = false
    },
  },
}
</script>
<style scoped lang="scss"></style>
