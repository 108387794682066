import { render, staticRenderFns } from "./ic-two-line-chart.vue?vue&type=template&id=1cfc770c&scoped=true"
import script from "./ic-two-line-chart.vue?vue&type=script&lang=js"
export * from "./ic-two-line-chart.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cfc770c",
  null
  
)

export default component.exports