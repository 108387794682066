<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.2 11.1926L6 6.38459L9.2 9.58459L16 1.93659L14.872 0.808594L9.2 7.18459L6 3.98459L0 9.99259L1.2 11.1926Z"
      fill="#6366FA"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcTrend',
  props: {
    size: {
      type: [String, Number],
      default: 16,
      required: false,
    },
  },
}
</script>
