<template>
  <div class="max-h-140 text-bb-text-default p-6 max-w-180">
    <div class="space-y-5">
      <h4 class="h4">Business Description Troubleshoot</h4>
      <p>
        A precise “Business Description” helps our AI generate better keyword suggestions. Use this tool to refine and
        test it for relevance.
      </p>
    </div>
    <div class="w-full relative">
      <div class="flex flex-col gap-y-2">
        <h4 class="h4 pt-8">Business description</h4>
        <p>Edit or update your business description</p>
      </div>
      <text-area
        v-model="troubleshootForm.businessDescription"
        input-name="business-description"
        input-id="business-description"
        :min-length="inputLengths.businessDescription.min"
        :max-length="inputLengths.businessDescription.max"
        :error-message="businessDescriptionErrorMessage"
        @focusout="$v.troubleshootForm.businessDescription.$touch"
      />
    </div>
    <div class="flex flex-col gap-y-2">
      <h4 class="h4 pt-8">Help the AI</h4>
      <p>
        Our models still have a lot to learn! Help our AI with extra information it should keep in mind when working
        with your account
      </p>
    </div>
    <div class="flex flex-col">
      <text-area
        v-model="troubleshootForm.extraInfo"
        input-name="help-the-ai"
        input-id="help-the-ai"
        :min-length="inputLengths.extraInfo.min"
        :max-length="inputLengths.extraInfo.max"
        :error-message="extraInfoErrorMessage"
        :rows="5"
        @focusout="$v.troubleshootForm.extraInfo.$touch"
      />
      <search-input
        ref="offering"
        v-model="troubleshootForm.selectedOffering"
        v-scroll-to-me
        placeholder="Select Offering"
        input-id="offering"
        label="Link Offering"
        sub-label="Associate your offering to the Business description"
        :options="offeringOptions"
        @select-item="selectOffering"
      >
        <template #label-icon>
          <ic-link
            size="24"
            class="text-bb-disabled-buttons"
          />
        </template>
      </search-input>

      <div class="border border-neutral-100 rounded-lg mt-6 overflow-hidden">
        <div class="grid grid-cols-12 px-6 py-5 border-b text-bb-text-default">
          <div class="col-span-6">
            <h5 class="h5">Expressions</h5>
          </div>

          <div class="col-span-3 text-center my-auto">
            <h5 class="h5">Relevance</h5>
          </div>
          <div class="col-span-3 my-auto">
            <h5 class="h5 text-center">Intent</h5>
          </div>
        </div>
        <div
          v-for="(item, index) in topKeywordsForm"
          :key="index"
          class="hover:bg-bb-background-purple-0 hover:border-bb-brand-purple border-white border-l-4 duration-300 ease-linear pt-1 pb-1"
        >
          <div class="grid grid-cols-12 px-4 items-center">
            <div class="col-span-6">
              <text-input
                v-model="item.keyword"
                class="w-full"
                :input-name="`expression-${index + 1}`"
                :input-id="`expression-${index}`"
              />
            </div>

            <div class="col-span-3 ml-10">
              <div class="capitalize">{{ topKeywords[index].relevance }}</div>
            </div>
            <div class="col-span-3 my-auto text-center pl-7">
              <div
                class="flex items-center justify-center w-25 text-sm font-medium py-1 rounded-md"
                :class="`${topKeywords[index].intent}`"
              >
                <p class="capitalize">{{ topKeywords[index].intent }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row items-center mt-6 mb-20 gap-x-8">
        <p class="text-bb-text-secondary">Expressions are for testing purposes and will not be saved.</p>

        <merge-button-round
          button-type="secondary"
          class="w-64"
          @click="testOnExpressions"
        >
          <template v-if="isModalLoading">
            <ellipsis-loader :size="9" />
          </template>
          <template v-if="!isModalLoading"> Test on expressions </template>
        </merge-button-round>
      </div>
    </div>

    <div class="flex items-center justify-end gap-x-6 py-6 bg-white sticky bottom-0 z-10">
      <merge-button-round
        button-type="secondary"
        class="w-30"
        @click="$emit('close')"
      >
        <p>Cancel</p>
      </merge-button-round>
      <merge-button-round
        button-type="primary"
        class="w-30"
        @click="save"
      >
        <p>Save</p>
      </merge-button-round>
    </div>
  </div>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators'

import TextInput from '@/components/input/brightbid/TextInput.vue'
import TextArea from '@/components/input/brightbid/TextArea.vue'
import SearchInput from '@/components/input/brightbid/SearchInput.vue'
import EllipsisLoader from '../../loader/EllipsisLoader.vue'

import IcLink from '@/components/icon/brightbid/ic-link.vue'

export default {
  name: 'TroubleshootBusinessDescription',
  components: { TextArea, SearchInput, IcLink, TextInput, EllipsisLoader },
  props: {
    offerings: {
      type: Array,
      default: () => [],
    },
    topKeywords: {
      type: Array,
      default: () => [],
    },
    extraInfo: {
      type: String,
      default: '',
    },
    businessDescription: {
      type: String,
      default: '',
    },
    isModalLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      troubleshootForm: {
        businessDescription: '',
        extraInfo: '',
        selectedOffering: null,
      },
      inputLengths: {
        businessDescription: {
          min: 25,
          max: 150,
        },
        extraInfo: {
          max: 300,
        },
      },
      topKeywordsForm: [],
    }
  },
  computed: {
    offeringOptions() {
      return this.offerings.map(offering => ({
        value: offering.search_customer_product_id,
        label: offering.name,
      }))
    },
    businessDescriptionErrorMessage() {
      const lengths = this.inputLengths.businessDescription
      if (!this.$v.troubleshootForm.businessDescription.$dirty) return null
      if (!this.$v.troubleshootForm.businessDescription.required) return 'Description is required'
      if (!this.$v.troubleshootForm.businessDescription.minLength)
        return `Please use at least ${lengths.min} characters.`
      if (!this.$v.troubleshootForm.businessDescription.maxLength)
        return `Please use at most ${lengths.max} characters.`
      return null
    },
    extraInfoErrorMessage() {
      const lengths = this.inputLengths.extraInfo
      if (!this.$v.troubleshootForm.extraInfo.$dirty) return null
      if (!this.$v.troubleshootForm.extraInfo.maxLength) return `Please use at most ${lengths.max} characters.`
      return null
    },
  },
  mounted() {
    this.troubleshootForm = {
      businessDescription: this.businessDescription,
      extraInfo: this.extraInfo,
    }
    this.topKeywordsForm = structuredClone(this.topKeywords)
  },
  validations() {
    return {
      troubleshootForm: {
        businessDescription: {
          required,
          minLength: minLength(this.inputLengths.businessDescription.min),
          maxLength: maxLength(this.inputLengths.businessDescription.max),
        },
        extraInfo: {
          maxLength: maxLength(this.inputLengths.extraInfo.max),
        },
      },
    }
  },
  methods: {
    async testOnExpressions() {
      this.$v.troubleshootForm.$touch()
      if (this.$v.troubleshootForm.$invalid) return

      const payload = {
        business_description: this.troubleshootForm.businessDescription,
        extra_info: this.troubleshootForm.extraInfo,
        expressions: this.topKeywordsForm.filter(item => item.keyword.trim() !== '').map(item => item.keyword),
      }
      if (this.troubleshootForm.selectedOffering?.value) {
        payload.product_uuid = this.troubleshootForm.selectedOffering.value
      }

      this.$emit('test-on-expressions', payload, this.topKeywordsForm)
    },
    selectOffering(offering) {
      if (this.troubleshootForm.selectedOffering && offering.value === this.troubleshootForm.selectedOffering.value) {
        this.troubleshootForm.selectedOffering = { label: '', value: '' }
        this.$refs.offering.searchInput = null
        this.$refs.offering.selectedItem = null
        return
      }
      this.troubleshootForm.selectedOffering = offering
    },
    save() {
      this.$v.troubleshootForm.$touch()
      if (this.$v.troubleshootForm.$invalid) return

      const payload = {
        description: this.troubleshootForm.businessDescription,
        extra_info: this.troubleshootForm.extraInfo,
      }

      this.$emit('update-business-description-and-extra-info', payload)
    },
  },
}
</script>

<style scoped lang="scss">
.transactional {
  background: #beddff;
}
.informational {
  background: #ffaacc;
}
.commercial {
  background: #b3ffe5;
}
.navigational {
  background: #ffe9ab;
}
</style>
