<template>
  <div class="space-y-4">
    <div class="px-6 py-4 flex justify-between bg-white border-b border-neutral-50">
      <div class="text-bb-text-default">
        <h3 class="h3">Competitor Monitoring</h3>
        <p class="text-sm">
          Get insights on competitors, track rankings, and stay updated on changes in search results
        </p>
      </div>
      <div class="flex items-center divide-x divide-neutral-100">
        <date-picker
          v-model="datePickerDates"
          range
          :clearable="false"
          :shortcuts="shortcuts"
          :disabled-date="disableDates"
          @input="pickDates"
        />
        <div class="px-6">
          <ic-list
            :size="20"
            class="text-bb-text-default hover:text-bb-brand-purple duration-300 ease-linear cursor-pointer"
            @click="$router.push({ name: 'all-groups' })"
          />
        </div>
        <div class="px-6">
          <ic-solid-cog
            :size="20"
            class="text-bb-text-default hover:text-bb-brand-purple duration-300 ease-linear cursor-pointer"
            @click="showSettingsModal"
          />
        </div>
      </div>
    </div>
    <div
      v-if="selectedMonitoringGroup.id"
      class="px-6"
    >
      <monitoring-group-card
        read-only
        right-button
        :monitoring-group="selectedMonitoringGroup"
        class="shadow-bb-shadow"
        @open-modal="switchMonitoringGroup"
      >
        <template #right-icon>
          <ic-swap />
        </template>
      </monitoring-group-card>
    </div>

    <div class="px-6">
      <page-tabs
        :tabs="pageTabs"
        :selected-tab="'overview'"
      >
        <div
          v-for="tab in pageTabs"
          :key="tab.value"
          class="cursor-pointer z-10 duration-300 ease-linear"
          style="border-bottom-width: 3px"
          :class="
            $route.name === tab.value
              ? 'border-bb-brand-purple text-bb-brand-purple'
              : 'text-bb-text-secondary border-neutral-0 hover:border-neutral-100'
          "
        >
          <div
            class="px-6 pb-2 flex gap-2 items-center"
            @click="selectTab(tab.value)"
          >
            <p>
              {{ tab.label }}
            </p>
            <div
              v-if="tab.value === 'infringements-detector-v2' && totalInfringements > 0"
              class="bg-bb-red-error text-xs text-white font-bold h-6 w-6 rounded-md flex items-center justify-center"
            >
              <p>{{ totalInfringements }}</p>
            </div>
          </div>
        </div>
      </page-tabs>
    </div>
  </div>
</template>

<script>
import IcList from '@/components/icon/ic-list.vue'
import IcSolidCog from '@/components/icon/brightbid/ic-solid-cog.vue'
import IcSwap from '@/components/icon/brightbid/ic-swap.vue'
import MonitoringGroupCard from '@/views/site/search/competitor_monitoring_v2/components/MonitoringGroupCard.vue'
import PageTabs from '@/components/shared/PageTabs.vue'

import DatePicker from 'vue2-datepicker'

import { mapActions, mapState } from 'vuex'

export default {
  name: 'MainHeader',
  components: { PageTabs, MonitoringGroupCard, IcSolidCog, IcList, IcSwap, DatePicker },
  props: {
    selectedMonitoringGroup: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      datePickerDates: [],
      pageTabs: [
        { label: 'Overview', value: 'overview-v2' },
        { label: 'Infringements Detector', value: 'infringements-detector-v2' },
        { label: 'Competitors List', value: 'all-competitors-v2' },
      ],
      shortcuts: [
        {
          text: 'Last 7 Days',
          onClick: () => {
            const today = new Date()
            const last7Days = new Date()
            last7Days.setDate(today.getDate() - 6)
            return [last7Days, today]
          },
        },
        {
          text: 'Last 14 Days',
          onClick: () => {
            const today = new Date()
            const last14Days = new Date()
            last14Days.setDate(today.getDate() - 13)
            return [last14Days, today]
          },
        },
        {
          text: 'Last 30 Days',
          onClick: () => {
            const today = new Date()
            const last30Days = new Date()
            last30Days.setDate(today.getDate() - 29)
            return [last30Days, today]
          },
        },
      ],
      totalInfringements: this.infringementSummary?.newCount ?? 0,
    }
  },
  computed: {
    ...mapState('competitorMonitoringV2', ['dateRange', 'infringementSummary']),
  },
  watch: {
    dateRange: {
      deep: true,
      immediate: true,
      handler() {
        this.datePickerDates = structuredClone(this.dateRange)
      },
    },
  },
  methods: {
    ...mapActions('competitorMonitoringV2', ['resetDateRange']),
    ...mapActions('toast', ['loadToast']),
    showSettingsModal() {
      this.$emit('show-selected-monitoring-settings')
    },
    switchMonitoringGroup() {
      this.$emit('show-switch-monitoring-modal')
    },
    async selectTab(tab) {
      this.$router.push({ name: tab })
    },
    async pickDates(dateRange) {
      // Less than the creation date
      const isLessThanCreationDate = dateRange[1] < new Date(this.createdAt)

      // Exceeds the current date
      const isExceedsCurrentDate = dateRange[0] > new Date()

      if (isLessThanCreationDate || isExceedsCurrentDate) {
        await this.resetDateRange()
        await this.loadToast({
          title: 'Warning',
          message: 'Invalid date range. Please select a valid date range. Default date range selected.',
          type: 'warning',
        })

        this.datePickerDates = this.dateRange
        this.$emit('pick-date', this.datePickerDates)
        return
      }

      this.datePickerDates = dateRange
      this.$emit('pick-date', dateRange)
    },
    disableDates(date) {
      const startDate = new Date(this.createdAt)
      startDate.setDate(startDate.getDate() - 1)
      return date < startDate
    },
  },
}
</script>

<style lang="scss">
@import 'node_modules/vue2-datepicker/scss/index.scss';
.mx-input-wrapper {
  cursor: pointer;
  width: 220px;
}
.mx-datepicker-range {
  width: 220px;
}

.mx-input {
  border-radius: 8px;
  height: 40px;
  border: #e5e7eb solid 1px;
  padding: 8px 10px;
  font-size: 14px;
}

.mx-input:hover {
  border: $bb-brand-purple solid 1px;
}
.mx-input:focus {
  border: $bb-brand-purple solid 1px;
}
</style>
