<template>
  <div class="rounded-lg w-full overflow-hidden shadow-md">
    <div
      class="border-l-8 h-full"
      :class="cardClass"
    >
      <div class="flex justify-between items-center h-full">
        <div class="flex items-start gap-4 h-full">
          <slot name="card-icon" />
          <div class="pt-1 space-y-3">
            <h5 class="h5">{{ cardTitle }}</h5>

            <slot name="card-content"></slot>
          </div>
        </div>
        <slot name="right-icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationCard',
  props: {
    cardClass: {
      type: String,
      default: 'border-neutral-50 p-5',
    },
    cardTitle: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped lang="scss"></style>
