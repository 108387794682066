<template>
  <div>
    <div
      v-if="auditWelcomeMessage"
      class="container rounded-2xl p-6 flex flex-row gap-4 max-w-full lg:max-w-screen-xl mx-auto"
    >
      <div class="max-w-4xl">
        <div class="flex flex-col gap-2 text-white text-base">
          <div class="flex flex-row gap-2 font-bold">
            <p>Audit Finished! 🚀 Let’s explore the potential performance improvements</p>
          </div>
          <div>
            <div class="flex flex-wrap gap-2 mt-2 text-sm">
              <div
                v-if="!isZero(auditDetails.last_30_days_performance_data?.conversions)"
                class="rounded-full px-3 py-1 capitalize flex flex-row gap-2 bg-bb-brand-purple items-center"
              >
                <IcTrendingUp class="text-bb-brand-green" />
                <span> Conversion Uplift </span>
                <span class="bg-bb-purple-700 rounded-full px-3 py-1">
                  {{ formatValue(auditWelcomeMessage?.potential_conversion_uplift_range?.min_percentage) }} -
                  {{ formatValue(auditWelcomeMessage?.potential_conversion_uplift_range?.max_percentage) }}
                </span>
              </div>
              <div
                v-if="!isZero(auditDetails.last_30_days_performance_data?.cost)"
                class="rounded-full px-3 py-1 capitalize flex flex-row gap-2 bg-bb-brand-purple items-center"
              >
                <IcTrendingDown class="text-bb-brand-green" />
                <span> Cost Reduction </span>
                <span class="bg-bb-purple-700 rounded-full px-3 py-1">
                  {{ formatValue(auditWelcomeMessage?.potential_cost_savings_range?.min_percentage) }} -
                  {{ formatValue(auditWelcomeMessage?.potential_cost_savings_range?.max_percentage) }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="font-medium text-sm">
          <div class="flex mt-4">
            <p class="text-white">Key drivers for your account to improve performance:</p>
          </div>
          <ul
            v-for="driver in auditWelcomeMessage?.key_drivers"
            :key="driver"
            class="text-white list-disc pl-6"
          >
            <li>{{ driver }}.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import IcTrendingUp from 'vue-material-design-icons/TrendingUp'
import IcTrendingDown from 'vue-material-design-icons/TrendingDown'

export default {
  name: 'AuditWelcomeMessage',
  components: {
    IcTrendingUp,
    IcTrendingDown,
  },
  computed: {
    ...mapState('audit', ['auditDetails']),
    auditWelcomeMessage() {
      const welcomeMessage = this.auditDetails.welcome_message
      return welcomeMessage && Object.keys(welcomeMessage).length > 0 ? welcomeMessage : null
    },
  },
  methods: {
    formatValue(value) {
      if (typeof value !== 'string') {
        return `${value}%`
      }
      return value.includes('%') ? value : `${value}%`
    },
    isZero(value) {
      return value === '0' || value === 0 || value.startsWith('0 ')
    },
  },
}
</script>

<style scoped>
.container {
  background: linear-gradient(45deg, #7c7ff7 6%, #6366fa 100%);
}
</style>
