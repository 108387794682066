var render = function render(){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"border-b border-neutral-50"},[_c('tr',_vm._l((_vm.headers),function(header,index){return _c('th',{key:index,class:[
        index === 0 ? 'sticky left-0 bg-white border-r border-neutral-50 z-20' : '',
        _vm.lastItemSticky && index === _vm.headers.length - 1 ? 'sticky right-0 bg-white border-l border-neutral-50' : '',
      ]},[_c('div',{staticClass:"h-full whitespace-no-wrap w-full flex gap-1 items-center text-bb-text-default p-4 pr-0",class:{ 'justify-end': header.position === 'right', 'justify-center': header.position === 'center' },on:{"mouseenter":function($event){_vm.hoveredHeader = header.value},"mouseleave":function($event){_vm.hoveredHeader = null}}},[(index === 0 && _vm.selectAll)?_c('CheckBox',{staticClass:"pr-3",attrs:{"input-id":"select-all","input-name":"select-all","value":_vm.allSelected,"indeterminate":_vm.partiallySelected},on:{"input":_vm.onSelectAll}}):_vm._e(),_c('p',{staticClass:"text-sm",class:[
            _vm.sortingOrder.column === header.value ? 'font-medium' : 'font-medium',
            header.sortable ? 'cursor-pointer' : 'cursor-default',
          ],on:{"click":function($event){return _vm.onHeaderClick(header)}}},[_vm._v(" "+_vm._s(header.label)+" ")]),_c('div',{staticClass:"h-5 w-5"},[(header.sortable)?_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.onHeaderClick(header)}}},[(_vm.sortingOrder.column === header.value || _vm.hoveredHeader === header.value)?_c('DropdownArrow',{attrs:{"direction":_vm.sortingOrder.order === 'ASCENDING' && _vm.sortingOrder.column === header.value ? 'down' : 'up'}}):_vm._e()],1):_vm._e()])],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }