<template>
  <div
    v-click-outside="clickOutside"
    :class="{
      'text-bb-disabled-buttons cursor-not-allowed ': disabled,
      'cursor-pointer hover:bg-neutral-50': !disabled,
      'bg-neutral-50': isDropdownVisible,
    }"
  >
    <div
      class="relative text-bb-neutral-gray p-2"
      @click="disabled ? null : (isDropdownVisible = !isDropdownVisible)"
    >
      <div :class="{ 'text-bb-brand-purple': isDropdownVisible }">
        <slot name="icon"></slot>
      </div>
      <p>{{ title }} <span v-if="title && selectionLabel && !hideLabel">:</span></p>
      <p v-if="!hideLabel">{{ selectionLabel }}</p>
      <div
        v-if="!multiple && selectionLabel && !hideLabel"
        @click.stop="$emit('toggle-ascending-descending')"
      >
        <slot name="arrow" />
      </div>
    </div>
    <div
      v-if="isDropdownVisible"
      class="absolute max-h-50 min-w-32 bg-white rounded-md shadow-md my-2 dropdown-scrollbar overflow-y-auto z-40"
      :class="dropdownPosition[position]"
    >
      <slot name="dropdown">
        <div
          v-for="option in options"
          :key="option.value"
          class="py-2 px-3 hover:bg-bb-secondary-purple cursor-pointer flex items-center gap-2"
          :class="{ 'text-bb-disabled-buttons': option?.isDisabled }"
          @click="option?.isDisabled ? null : selectItem(option)"
        >
          <div class="flex flex-row justify-between w-full gap-2 items-center">
            <p>{{ option.label }}</p>
            <div
              v-if="multiple"
              @click="option?.isDisabled ? null : selectItem(option)"
            >
              <input
                type="checkbox"
                :checked="isSelected(option)"
                :disabled="option?.isDisabled"
                @change.stop="selectItem(option)"
              />
            </div>
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DropdownSelect',
  props: {
    selectionValue: {
      type: [Object, Array],
      required: true,
    },
    position: { type: String, default: 'bottomRightToLeft' },
    title: { type: String, default: '' },
    options: { type: Array, required: true },
    disabled: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    hideLabel: { type: Boolean, default: false }, // New prop to hide the label
  },
  data() {
    return {
      selectedItem: { label: '', value: '' },
      selectedItems: [],
      isDropdownVisible: false,
      dropdownPosition: {
        bottomRightToLeft: ['-top-0', 'right-0'],
        bottomLeftToRight: ['-top-0', 'left-0'],
      },
    }
  },
  computed: {
    selectionLabel() {
      if (this.multiple) {
        const labels = (this.selectionValue || []).map(item => item.label)
        return labels.join(', ')
      } else {
        return this.selectionValue.label
      }
    },
  },
  watch: {
    selectionValue: {
      immediate: true,
      handler(newVal) {
        if (this.multiple && Array.isArray(newVal)) {
          this.selectedItems = newVal
        } else if (!this.multiple && newVal && newVal.value !== '') {
          this.selectedItem = newVal
        }
      },
    },
  },
  methods: {
    selectItem(item) {
      if (this.multiple) {
        const exists = this.selectedItems.find(i => i.value === item.value)
        if (exists) {
          this.selectedItems = this.selectedItems.filter(i => i.value !== item.value)
        } else {
          this.selectedItems.push(item)
        }
        this.$emit('select-item', this.selectedItems)
      } else {
        this.selectedItem = item
        this.isDropdownVisible = false
        this.$emit('select-item', this.selectedItem)
      }
    },
    isSelected(option) {
      return !!this.selectedItems.find(i => i.value === option.value)
    },
    clickOutside() {
      if (!this.isDropdownVisible) return
      this.isDropdownVisible = false
    },
  },
}
</script>

<style scoped lang="scss"></style>
