<template>
  <div>
    <div class="p-6 space-y-6">
      <div class="bg-bb-red-50 mr-10 rounded-md px-6">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-3">
            <img src="/content/images/brightbid/bidbot_emotion_0.svg" />
            <ic-warning class="text-bb-red-error" />
            <h5 class="h5 font-bold">{{ dialogText }}</h5>
          </div>
          <div>
            <a
              href="https://support.google.com/legal/troubleshooter/1114905?_gl=1*emgne7*_ga*MTAyMDAwOTg4My4xNzE5NTgzNzA5*_ga_V9K47ZG8NP*MTcyMTIxNTk1Mi4yMi4wLjE3MjEyMTU5NTIuNjAuMC4w#ts=2981967%2C2981971%2C12981969%2C13037070"
              target="_blank"
            >
              <div class="flex items-center gap-3 bg-white hover:bg-neutral-0 py-2 px-5 rounded-full">
                <p class="text-bb-red-error">File a complaint to Google</p>
                <ic-open-new-window
                  class="text-bb-red-error"
                  :size="12"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div>
        <infringement-summary-table
          :infringements="infringements || []"
          @infringement-action="$emit('infringement-action', $event)"
        />
      </div>
      <div
        class="absolute bottom-6 left-6 flex gap-3 items-center text-bb-brand-purple font-medium cursor-pointer"
        @click="addToWhitelist"
      >
        <ic-flag :size="16" />
        <p>Add to White List</p>
      </div>
      <div
        class="absolute bottom-6 right-6 flex gap-3 items-center text-bb-brand-purple font-medium cursor-pointer"
        @click="markAllAsSolved"
      >
        <ic-checked :size="16" />
        <p>Mark All as Solved</p>
      </div>
    </div>
    <div
      v-if="!infringements || isModalLoading"
      class="rounded-md flex h-full items-center justify-center absolute top-0 mx-auto w-full inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <brightbid-loader>
        <p v-if="loadingMessage">{{ loadingMessage }}</p>
      </brightbid-loader>
    </div>
  </div>
</template>

<script>
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'
import IcFlag from '@/components/icon/brightbid/ic-flag.vue'
import IcChecked from 'vue-material-design-icons/CheckCircleOutline'
import IcWarning from '@/components/icon/brightbid/ic-warning.vue'
import IcOpenNewWindow from '@/components/icon/brightbid/ic-open-new-window.vue'
import InfringementSummaryTable from '@/views/site/search/competitor_monitoring_v2/infringements/competitor_infringement_summary/InfringementSummaryTable.vue'

export default {
  name: 'CompetitorInfringementSummaryModal',
  components: {
    IcOpenNewWindow,
    IcWarning,
    InfringementSummaryTable,
    IcChecked,
    IcFlag,
    BrightbidLoader,
  },
  props: {
    competitorUrl: {
      type: String,
      required: true,
    },
    competitorName: {
      type: String,
      required: true,
    },
    infringements: {
      type: Array,
      required: true,
    },
    isModalLoading: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    dialogText() {
      if (!this.infringements?.length) return `No Infringements from “${this.competitorUrl}”`
      return `${this.infringements.length || 0} ${(this.infringements?.length ?? 0) > 1 ? 'Infringements' : 'Infringement'} from “${this.competitorUrl}”`
    },
  },
  methods: {
    addToWhitelist() {
      this.$emit('infringement-action', {
        action: 'whitelist',
        competitorName: this.competitorName,
        competitorUrl: this.competitorUrl,
      })
    },
    markAllAsSolved() {
      this.$emit('infringement-action', {
        action: 'mark-all-as-solved',
        competitorName: this.competitorName,
        competitorUrl: this.competitorUrl,
      })
    },
  },
}
</script>

<style scoped lang="scss"></style>
