<template>
  <button
    :class="buttonClasses"
    :disabled="disabled"
    @click.prevent="handleClick"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
  >
    <ic-stars
      class="z-10"
      :class="[
        enabled && !hovered ? 'text-bb-brand-purple' : '',
        enabled && hovered ? 'text-white' : '',
        !enabled && hovered ? 'text-bb-brand-purpl' : '',
        !enabled && !hovered ? 'text-bb-brand-purple' : '',
      ]"
    />
    <span class="z-10 w-32 text-left relative">
      <p
        class="absolute inset-0 flex items-center justify-start transition-opacity duration-300"
        :class="{ 'opacity-0': !(enabled && !hovered), 'opacity-100': enabled && !hovered }"
      >
        Autopilot running...
      </p>
      <p
        class="absolute inset-0 flex items-center justify-start transition-opacity duration-300"
        :class="{ 'opacity-0': !(enabled && hovered), 'opacity-100': enabled && hovered, 'text-white': hovered }"
      >
        Disable Autopilot
      </p>
      <p
        v-if="!enabled"
        class="transition-colors duration-300 text-bb-brand-purple"
        :class="{ 'text-white': hovered }"
      >
        Enable Autopilot
      </p>
    </span>
  </button>
</template>

<script>
import IcStars from '@/components/icon/brightbid/ic-stars.vue'

export default {
  components: {
    IcStars,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovered: false,
    }
  },
  computed: {
    buttonClasses() {
      const baseClasses = [
        'inline-flex gap-2 items-center justify-center',
        'min-w-30',
        'min-h-10',
        'py-2 px-6',
        'rounded-full',
        'text-sm font-medium ',
        'transition delay-100 duration-200 ease-in-out focus:outline-none outline-none',
        'relative overflow-hidden hover:bg-bb-brand-purple ',
      ]

      let stateBasedClasses = []

      if (this.disabled) {
        stateBasedClasses = ['text-white bg-bb-disabled-buttons']
      } else if (this.enabled) {
        stateBasedClasses = [
          'text-bb-brand-purple bg-white hover:bg-bb-brand-purple hover:text-white active:bg-black active:text-white',
        ]
      } else {
        stateBasedClasses = ['text-white', 'button-gradient', 'active:bg-black active:text-white']
      }

      return [...baseClasses, ...stateBasedClasses]
    },
  },

  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit('click')
      }
    },
    handleMouseOver() {
      this.hovered = true
    },
    handleMouseLeave() {
      this.hovered = false
    },
  },
}
</script>

<style scoped>
.text-bb-brand-purple {
  color: #6366fa;
}

.text-white {
  color: #ffffff;
}

.text-container {
  position: relative;
}

.fade-text-transition {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.3s ease;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: left;
}

.fade-text-visible {
  opacity: 1;
}
</style>
