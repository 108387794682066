<template>
  <page class="main-container base-scrollbar">
    <page-section
      full-width
      bottom-border
    >
      <div class="flex flex-row justify-between">
        <page-title>
          <div class="h3 flex items-center gap-4 mx-5">
            <p>External Audits (last 90 days performance data)</p>
          </div>
        </page-title>
      </div>
    </page-section>
    <page-section full-width>
      <p class="text-bb-text-secondary pb-4 p3 mx-5">
        Here you can find the audits which have been ran outside the BrightBid hub.
      </p>
      <text-input
        v-model="searchQuery"
        class="w-64 ml-5"
        input-name="search-audits"
        input-id="search-audits"
        input-type="text"
        placeholder="Search Audits..."
      >
      </text-input>
      <bb-table
        class="mx-5 my-6 shadow-bb-shadow rounded-md table-auto"
        :headers="headers"
        :data="filteredAuditExternalCustomers"
        :is-loading="isLoading"
        :scroll-y="true"
        :default-sorting-order="defaultSortingOrder"
        max-table-height="calc(100vh - 400px)"
      >
        <template #rows="{ tableData }">
          <tbody>
            <tr
              v-for="customer in tableData"
              :key="customer.id"
              class="h-18 text-sm hover:bg-bb-neutral-100"
            >
              <td class="bg-white px-4 text-left pl-4">
                {{ customer.google_customer_name }} - {{ formatCustomerId(customer.google_customer_id) }}
              </td>
              <td class="bg-white text-center">{{ customer.score ? customer.score + '%' : '' }}</td>
              <td class="bg-white text-right whitespace-nowrap pr-6">
                {{ customer?.last_90_days_performance_data?.impressions || '-' }}
              </td>
              <td class="bg-white text-right whitespace-nowrap pr-6">
                {{ customer?.last_90_days_performance_data?.clicks || '-' }}
              </td>
              <td class="bg-white text-right whitespace-nowrap pr-6 min-w-32">
                {{ customer?.last_90_days_performance_data?.cost || '-' }}
              </td>
              <td class="bg-white text-right whitespace-nowrap pr-6">
                {{ customer?.last_90_days_performance_data?.average_cpc || '-' }}
              </td>
              <td class="bg-white text-right whitespace-nowrap pr-6">
                {{ customer?.last_90_days_performance_data?.conversions || '-' }}
              </td>
              <td class="bg-white text-right whitespace-nowrap pr-6">
                {{ customer?.last_90_days_performance_data?.conversion_value || '-' }}
              </td>
              <td class="bg-white text-right whitespace-nowrap pr-6">
                {{ customer?.last_90_days_performance_data?.ctr || '-' }}
              </td>
              <td class="bg-white text-right whitespace-nowrap pr-6">
                {{ customer?.last_90_days_performance_data?.conv_rate || '-' }}
              </td>
              <td class="bg-white text-right whitespace-nowrap pr-6">
                {{ customer?.last_90_days_performance_data?.cpa || '-' }}
              </td>
              <td class="bg-white text-right whitespace-nowrap pr-6 min-w-32">
                {{ customer?.last_90_days_performance_data?.roas || '-' }}
              </td>
              <td class="bg-white text-right whitespace-nowrap pr-6">
                {{ customer?.last_90_days_performance_data?.aov || '-' }}
              </td>
              <td class="bg-white pl-4 pr-6 text-right pNum">{{ formatCreatedDate(customer.created_at) }}</td>
              <td class="bg-white px-4 text-center">
                <div
                  class="text-bb-brand-purple font-medium disabled cursor-pointer hover:opacity-75"
                  @click="$router.push({ name: 'audit:external:details', params: { id: customer.google_customer_id } })"
                >
                  View
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </bb-table>
    </page-section>
  </page>
</template>

<script>
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection'
import BbTable from '@/components/table/BbTable'
import { mapState, mapActions } from 'vuex'
import dayjs from 'dayjs'
import TextInput from '@/components/input/brightbid/TextInput.vue'

export default {
  name: 'ExternalAudits',
  components: {
    Page,
    PageSection,
    BbTable,
    TextInput,
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          value: 'google_customer_name',
          label: 'Google Ads Account Name',
          sortable: true,
          sorting: 'null',
          position: 'left',
        },
        {
          value: 'score',
          label: 'Score',
          sortable: true,
          sorting: null,
          position: 'center',
        },
        {
          value: 'impressions',
          label: 'Impressions',
          sortable: true,
          sorting: 'ASCENDING',
          position: 'right',
        },
        {
          value: 'clicks',
          label: 'Clicks',
          sortable: true,
          sorting: 'ASCENDING',
          position: 'right',
        },
        {
          value: 'cost',
          label: 'Cost',
          sortable: true,
          sorting: 'ASCENDING',
          position: 'right',
        },
        {
          value: 'average_cpc',
          label: 'Avg. CPC',
          sortable: true,
          sorting: 'ASCENDING',
          position: 'right',
        },
        {
          value: 'conversions',
          label: 'Conversion',
          sortable: true,
          sorting: 'ASCENDING',
          position: 'right',
        },
        {
          value: 'conversion_value',
          label: 'Conv. Value',
          sortable: true,
          sorting: 'ASCENDING',
          position: 'right',
        },
        {
          value: 'ctr',
          label: 'CTR',
          sortable: true,
          sorting: 'ASCENDING',
          position: 'right',
        },
        {
          value: 'conv_rate',
          label: 'Conv. Rate',
          sortable: true,
          sorting: 'ASCENDING',
          position: 'right',
        },
        {
          value: 'cpa',
          label: 'CPA',
          sortable: true,
          sorting: 'ASCENDING',
          position: 'right',
        },
        {
          value: 'roas',
          label: 'ROAS',
          sortable: true,
          sorting: 'ASCENDING',
          position: 'right',
        },
        {
          value: 'aov',
          label: 'AOV',
          sortable: true,
          sorting: 'ASCENDING',
          position: 'right',
        },
        {
          value: 'created_at',
          label: 'Date',
          sortable: true,
          sorting: 'ASCENDING',
          position: 'right',
        },
        {
          value: 'action',
          label: 'Action',
          sortable: false,
          sorting: null,
          position: 'center',
        },
      ],
      defaultSortingOrder: { order: 'ASCENDING', column: 'created_at' },
      searchQuery: '',
    }
  },

  computed: {
    ...mapState('audit', ['auditExternalCustomers']),
    filteredAuditExternalCustomers() {
      const searchTerm = this.searchQuery.toLowerCase().trim()
      if (!searchTerm)
        return this.auditExternalCustomers.filter(
          customer => customer.status !== 'running' && customer.status !== 'pending',
        )

      return this.auditExternalCustomers.filter(customer => {
        const nameMatch = customer.google_customer_name?.toLowerCase().includes(searchTerm)
        const rawId = customer.google_customer_id?.toLowerCase()
        const normalizedId = this.normalizeString(customer.google_customer_id)
        const formattedId = this.formatCustomerId(customer.google_customer_id).toLowerCase()

        const idMatch =
          rawId.includes(searchTerm) || normalizedId.includes(searchTerm) || formattedId.includes(searchTerm)

        return (nameMatch || idMatch) && customer.status !== 'running' && customer.status !== 'pending'
      })
    },
  },

  async mounted() {
    this.isLoading = true
    await this.fetchAuditExternalCustomers()
    this.isLoading = false
  },

  methods: {
    ...mapActions('audit', ['fetchAuditExternalCustomers']),
    formatCreatedDate(date) {
      return dayjs(date).format('MM/DD/YYYY')
    },
    normalizeString(value) {
      return value.toString().replace(/[^0-9]/g, '') // Remove all non-numeric characters
    },
    formatCustomerId(id) {
      const normalizedId = this.normalizeString(id)
      return normalizedId.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') // Format as 123-123-1234
    },
  },
}
</script>

<style lang="scss">
.main-container {
  overflow-y: auto;
  height: calc(100vh - 103px);
}

tr td:first-child {
  position: sticky;
  left: 0;
  z-index: 10;
}

tr th:first-child {
  background: white;
  position: sticky;
  left: 0;
  top: 0;
}

thead tr {
  position: sticky;
  top: 0;
  background: white;
}

tbody {
  z-index: 0;
}

thead {
  z-index: 100 !important;
  position: sticky;
}

tr:nth-child(even) td {
  background: #f9fafb;
}
</style>
