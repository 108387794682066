<template>
  <thead class="border-b border-neutral-50">
    <tr>
      <th
        v-for="(header, index) in headers"
        :key="index"
        :class="[
          index === 0 ? 'sticky left-0 bg-white border-r border-neutral-50 z-20' : '',
          lastItemSticky && index === headers.length - 1 ? 'sticky right-0 bg-white border-l border-neutral-50' : '',
        ]"
      >
        <div
          class="h-full whitespace-no-wrap w-full flex gap-1 items-center text-bb-text-default p-4 pr-0"
          :class="{ 'justify-end': header.position === 'right', 'justify-center': header.position === 'center' }"
          @mouseenter="hoveredHeader = header.value"
          @mouseleave="hoveredHeader = null"
        >
          <!-- Render Header Label and Sorting -->
          <CheckBox
            v-if="index === 0 && selectAll"
            input-id="select-all"
            input-name="select-all"
            class="pr-3"
            :value="allSelected"
            :indeterminate="partiallySelected"
            @input="onSelectAll"
          />
          <p
            class="text-sm"
            :class="[
              sortingOrder.column === header.value ? 'font-medium' : 'font-medium',
              header.sortable ? 'cursor-pointer' : 'cursor-default',
            ]"
            @click="onHeaderClick(header)"
          >
            {{ header.label }}
          </p>
          <div class="h-5 w-5">
            <div
              v-if="header.sortable"
              class="cursor-pointer"
              @click="onHeaderClick(header)"
            >
              <DropdownArrow
                v-if="sortingOrder.column === header.value || hoveredHeader === header.value"
                :direction="sortingOrder.order === 'ASCENDING' && sortingOrder.column === header.value ? 'down' : 'up'"
              />
            </div>
          </div>
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
import DropdownArrow from '@/components/icon/brightbid/dropdown_arrow'
import CheckBox from '@/components/input/brightbid/CheckBox.vue'

export default {
  name: 'TableHeader',
  components: { DropdownArrow, CheckBox },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    sortingOrder: {
      type: Object,
      required: true,
    },
    lastItemSticky: {
      type: Boolean,
      default: false,
    },
    selectAll: {
      // New Prop to Enable "Select All" Checkbox
      type: Boolean,
      default: false,
    },
    allSelected: {
      // New Prop to Control Checkbox State
      type: Boolean,
      default: false,
    },
    partiallySelected: {
      // New Prop to Control Checkbox State
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hoveredHeader: null,
    }
  },
  methods: {
    onHeaderClick(header) {
      if (header.sortable) {
        this.$emit('sort-table', header)
      }
    },
    onSelectAll(checked) {
      // Method to Emit "Select All" Event
      this.$emit('select-all', checked)
    },
  },
}
</script>
