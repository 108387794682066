<template>
  <div class="rounded-2xl shadow-bb-shadow max-w-full lg:max-w-screen-xl p-3">
    <div class="flex flex-row gap-2 items-center m-2 pb-2">
      <div class="bg-neutral-0 p-1 rounded-md">
        <IcTrend />
      </div>
      <span class="text-bb-text-default text-base font-bold">Here's your last 30 days performance </span>
    </div>
    <div
      v-if="data"
      class="flex flex-row divide-x base-scrollbar w-full justify-between overflow-x-auto p-2"
    >
      <div
        v-for="(item, index) in metrics"
        :key="index"
        class="flex flex-col text-nowrap font-medium px-4 gap-2"
      >
        <div class="text-base text-bb-neutral-gray font-medium">{{ item.label }}</div>
        <div class="text-xl text-bb-text-headers font-bold">
          <div class="text-nowrap w-auto min-w-32">{{ data?.[item.key] || '-' }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcTrend from '@/components/icon/brightbid/ic-trend'

export default {
  name: 'AuditPerformanceBanner',
  components: {
    IcTrend,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      metrics: [
        { label: 'Impressions', key: 'impressions' },
        { label: 'Clicks', key: 'clicks' },
        { label: 'Cost', key: 'cost' },
        { label: 'Avg. CPC', key: 'average_cpc' },
        { label: 'Conversions', key: 'conversions' },
        { label: 'Conv. Value', key: 'conversion_value' },
        { label: 'CTR', key: 'ctr' },
        { label: 'Conv. Rate', key: 'conv_rate' },
        { label: 'CPA', key: 'cpa' },
        { label: 'ROAS', key: 'roas' },
        { label: 'AOV', key: 'aov' },
      ],
    }
  },
}
</script>
