<template>
  <div>
    <div class="mt-4 mb-10">
      <div class="sticky top-0 z-10 bg-white">
        <div
          v-if="campaignActivityHistory.length"
          class="flex h-14 justify-between items-center font-medium mx-6"
        >
          <div class="flex items-start gap-x-4">
            <div class="flex flex-wrap items-center gap-x-4 gap-y-2 pr-4 py-2 border-r-2">
              <div class="flex items-center gap-2">
                <ic-plus
                  size="20"
                  class="text-bb-purple-200 border-2 rounded-full border-bb-purple-200"
                />
                <p class="text-sm font-normal">
                  <span class="font-bold">{{ historySummaryCount.accept }}</span> Total Added
                </p>
              </div>
              <status status="positive">
                <template #status="{ status }">
                  <p class="text-sm font-normal capitalize">
                    <span class="font-bold">{{ historySummaryCount.positive }}</span> {{ status }}
                  </p>
                </template>
              </status>
              <status status="negative">
                <template #status="{ status }">
                  <p class="text-sm font-normal capitalize">
                    <span class="font-bold">{{ historySummaryCount.negative }}</span> {{ status }}
                  </p>
                </template>
              </status>
              <status status="pause">
                <template #status="{ status }">
                  <p class="text-sm font-normal capitalize">
                    <span class="font-bold">{{ historySummaryCount.pause }}</span> {{ status }}
                  </p>
                </template>
              </status>
            </div>
            <div class="flex items-center gap-2 py-2">
              <ic-danger class="text-bb-danger-red" />
              <p class="text-sm font-normal">
                <span class="font-bold">{{ historySummaryCount.rejected }}</span> Total Rejected
              </p>
            </div>
          </div>
          <!-- SEARCH -->
          <div class="flex gap-3 h-14 justify-between items-center font-medium">
            <text-input
              v-model="query"
              input-name="search"
              input-id="search"
              input-type="text"
              placeholder="Search..."
              padding-left="pl-10"
              class="w-300"
            >
              <template #prefix>
                <div class="absolute z-10 h-10 p-2 text-bb-disabled-gray">
                  <IcSearch />
                </div>
              </template>
            </text-input>
            <merge-button-round
              v-if="filteredHistory.length && selectedCampaign?.suffix"
              button-type="secondary"
              @click="openConfirmationModal(UNDO_TYPES.UNDO_ALL)"
            >
              <p class="text-bb-brand-purple w-16">Undo All</p>
            </merge-button-round>
          </div>
        </div>
        <div
          v-if="selectedActivities.length !== 0"
          class="w-full h-18 my-2 px-8 flex items-center justify-between bg-bb-secondary-purple"
        >
          <div class="flex gap-4">
            <CheckBox
              :indeterminate="selectedActivities.length !== nonRevertedActivities.length"
              :disabled="selectedActivities.length === 0"
              :value="selectedActivities.length === nonRevertedActivities.length"
              input-id="checkbox"
              input-name="checkbox"
              @input="clearSelection"
            />
            <p class="font-medium text-md">{{ selectedActivities.length }} Selected</p>
            <p
              v-if="selectedActivities.length !== nonRevertedActivities.length"
              class="font-medium text-md text-bb-brand-purple cursor-pointer"
              @click="selectionToggle('select-all')"
            >
              Select All
            </p>
            <p
              v-if="selectedActivities.length === nonRevertedActivities.length"
              class="font-medium text-md text-bb-brand-purple cursor-pointer"
              @click="selectionToggle('unselect-all')"
            >
              Unselect All
            </p>
          </div>
          <div class="flex gap-4 items-center">
            <MergeButtonRound
              button-type="secondary"
              class="w-full"
            >
              <div class="flex gap-2 items-center">
                <ic-edit
                  size="16"
                  class="text-bb-disabled-buttons"
                />
                Edit
              </div>
            </MergeButtonRound>
            <MergeButtonRound
              button-type="secondary"
              class="w-full"
              @click="openConfirmationModal(UNDO_TYPES.UNDO)"
            >
              <div class="flex gap-2 items-center text-bb-brand-purple">
                <ic-curved-arrow size="16" />
                Undo
              </div>
            </MergeButtonRound>
            <MergeButtonRound
              disabled
              button-type="secondary"
              @click="openConfirmationModal(UNDO_TYPES.UNDO_AND_REJECT)"
            >
              <div class="flex gap-2 items-center justify-center text-bb-disabled-buttons w-30">
                <ic-danger
                  class="text-bb-disabled-buttons"
                  size="16"
                />
                <p>Undo & Reject</p>
              </div>
            </MergeButtonRound>
            <kebab
              disabled
              :menu="kebabMenu"
            />
          </div>
        </div>
      </div>
      <div
        v-if="filteredHistory.length"
        class="mx-6 space-y-3"
      >
        <activity-card
          v-for="activity in activities"
          :key="activity.id"
          :card-stats="activityStats"
          :activity="activity"
          :is-checked="selectedActivityIds.includes(activity.id)"
          @select-activity="selectActivity(activity)"
          @click.native="openMetricsPanel(activity)"
        />
      </div>
      <div
        v-else
        class="mt-10"
      >
        <empty-state>
          <div>
            <p class="text-md font-medium">No activity history found</p>
          </div>
        </empty-state>
      </div>
    </div>

    <!--MODAL-->
    <bb-base-modal
      v-if="modalConfig"
      :width="modalConfig.size.width"
      :height="modalConfig.size.height"
      @close="closeModal"
    >
      <template #container>
        <confirm-undo-modal-form
          v-if="modalConfig.modal === 'confirm-undo-activity'"
          :undo-type="modalConfig.undoType"
          @confirm-undo-activity="confirmUndoActivity"
          @close-modal="closeModal"
        />
      </template>
    </bb-base-modal>
    <div
      v-if="metricsPanelActivity"
      class="metrics-panel border-l absolute z-20 bg-white right-0 top-0"
    >
      <metric-panel
        v-if="metricsPanelActivity"
        context="activityhistory"
        class="metrics-panel border-l absolute z-20 bg-white right-0 top-0"
        :keyword="metricsPanelActivity"
        :buttons="[
          {
            label: 'Reject',
            icon: 'ic-cross',
            iconClass: 'text-bb-error',
            type: 'secondary',
            events: {
              click: () => confirmUndoActivity(UNDO_TYPES.UNDO),
            },
          },
          {
            label: 'Undo',
            icon: 'ic-curved-arrow',
            events: {
              click: () => confirmUndoActivity(UNDO_TYPES.UNDO_AND_REJECT),
            },
          },
        ]"
        @close="closeMetricsPanel"
      />
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js'

import { UNDO_TYPES } from '@/utils/enums'
import ActivityCard from '@/views/keywords_suggestions/cards/ActivityCard'
import EmptyState from '@/components/brightbid_illustrations/EmptyState'
import bbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'
import ConfirmUndoModalForm from '@/components/modals/forms/ConfirmUndoModalForm.vue'
import Toast from '@/components/shared/Toast.vue'
import TextInput from '@/components/input/brightbid/TextInput.vue'
import IcSearch from 'vue-material-design-icons/Magnify.vue'
import IcPlus from '@/components/icon/ic-plus.vue'
import IcDanger from '@/components/icon/brightbid/ic-danger.vue'
import Status from '@/components/shared/Status.vue'
import IcEdit from '@/components/icon/ic-edit.vue'
import CheckBox from '@/components/input/brightbid/CheckBox.vue'
import IcCurvedArrow from '@/components/icon/brightbid/ic-curved-arrow.vue'
import Kebab from '@/components/icon/brightbid/kebab.vue'
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import { mapState } from 'vuex'
import MetricPanel from '@/views/keywords_suggestions/MetricPanel.vue'

const ConfirmUndoModalSize = { width: '560px', height: '322px' }
const ConfirmUndoModal = 'confirm-undo-activity'

export default {
  name: 'ActivityHistory',
  components: {
    CheckBox,
    IcEdit,
    IcSearch,
    ConfirmUndoModalForm,
    bbBaseModal,
    ActivityCard,
    EmptyState,
    TextInput,
    IcPlus,
    Status,
    IcDanger,
    IcCurvedArrow,
    Kebab,
    MergeButtonRound,
    MetricPanel,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      UNDO_TYPES,
      kebabMenu: [{ label: 'Custom Undo', value: 'custom-undo' }],
      activityStats: [
        { value: 'keyword', label: 'Suggestion', colSpan: 4, style: null },
        {
          firstRow: 'date',
          secondRow: 'date_reverted',
          label: 'Date',
          colSpan: 2,
          style: null,
        },
        {
          firstRow: 'matchType',
          secondRow: '',
          label: 'Match type',
          colSpan: 1,
          style: null,
        },
        { value: 'addAs', label: 'Added as', colSpan: 1, style: 'status' },
        { value: 'adGroup', label: 'Ad Group', colSpan: 2, style: null },
        {
          firstRow: 'user',
          secondRow: 'reverted_by',
          label: 'User',
          colSpan: 1,
          style: null,
        },
        { value: 'actionTaken', label: 'Action Taken', colSpan: 1, style: 'tag' },
        { value: 'action', label: null, colSpan: 1, style: 'button' },
      ],
      selectedCampaign: null,
      selectedActivities: [],
      selectedKeyword: {},
      modalConfig: null,
      metricsPanelActivity: null,
      query: '',
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    selectedActivityIds() {
      return this.selectedActivities.map(activity => activity.id)
    },
    campaignActivityHistory() {
      if (!this.selectedCampaign) return this.context.activity_history
      return this.context.activity_history.filter(activity => {
        if (this.selectedCampaign.value === activity.google_campaign_id) {
          return activity
        }
      })
    },
    filteredHistory() {
      if (!this.query) {
        return this.campaignActivityHistory
      }
      const rules = {
        shouldSort: true,
        isCaseSensitive: false,
        keys: ['keyword'],
      }
      const fuse = new Fuse(this.campaignActivityHistory, rules)
      return fuse.search(this.query).map(result => result.item)
    },
    historySummaryCount() {
      const activityTypeCount = {
        positive: 0,
        negative: 0,
        pause: 0,
        accept: 0,
        rejected: 0,
        reverted: 0,
      }
      this.campaignActivityHistory.forEach(activity => {
        switch (activity.action_taken.toLowerCase()) {
          case 'accept':
            activityTypeCount.accept++
            if (activity.label) {
              const labelKey = activity.label.toLowerCase()
              activityTypeCount[labelKey] = (activityTypeCount[labelKey] || 0) + 1
            }
            break
          case 'rejected':
            activityTypeCount.rejected++
            break
          case 'reverted':
            activityTypeCount.reverted++
            break
        }
      })
      return activityTypeCount
    },
    activities() {
      return this.filteredHistory.map(item => {
        const isReverted = item.reverted_at && item.reverted_by ? true : false
        const suggestionJson = item.suggestion_json || {}

        return {
          id: item.id,
          keyword: item.keyword,
          date: item.created_at,
          matchType: item.match_type,
          addAs: item.label ? item.label.toLowerCase() : '',
          adGroup: item.ad_group_name,
          user: item?.added_by || '-',
          intent: item.intent || '-',
          actionTaken: item.action_taken ? item.action_taken : '',
          isAutoPilot: item.is_auto_pilot,
          action: isReverted ? null : 'undo',
          campaignId: item.google_campaign_id,
          adGroupId: item.ad_group_id,
          date_reverted: item.reverted_at || null,
          reverted_by: item?.reverted_by || '-',
          userPrefix: `${item?.original_action_taken === 'Accept' ? 'Added by ' : 'Rejected by '}`,
          datePrefix: `${item?.original_action_taken === 'Accept' ? 'Accepted on ' : 'Rejected on '}`,
          isReverted,

          // Metrics Data
          campaign_name: suggestionJson.campaign_name || '-',
          metrics_cost: suggestionJson.metrics_cost || '-',
          metrics_clicks: suggestionJson.metrics_clicks || '-',
          metrics_impressions: suggestionJson.metrics_impressions || '-',
          metrics_cts: suggestionJson.metrics_ctr || '-',
          metrics_cpc: suggestionJson.metrics_cpc || '-',
          metrics_cpa: suggestionJson.metrics_cpa || '-',
          metrics_cpc_campaign: suggestionJson.metrics_cpc_campaign || '-',
          metricsCpa: suggestionJson.metrics_cpa || '-',
          metrics_cpa_campaign: suggestionJson.metrics_cpa_campaign || '-',
          metrics_cvr: suggestionJson.metrics_cvr || '-',
          metrics_ctr: suggestionJson.metrics_ctr || '-',
          metrics_conversions: suggestionJson.metrics_conversions || '-',
          metrics_conversionsValue: suggestionJson.metrics_conversionsValue || '-',
          metrics_allConvRate: suggestionJson.metrics_allConvRate || '-',
          cpc_vs_campaign: suggestionJson.cpc_vs_campaign || '-',
          cpa_vs_campaign: suggestionJson.cpa_vs_campaign || '-',
          cvr_vs_campaign: suggestionJson.cvr_vs_campaign || '-',
          allConvRate_vs_campaign: suggestionJson.allConvRate_vs_campaign || '-',
          bright_perf_vs_campaign: suggestionJson.bright_perf_vs_campaign || '-',
          data_date_range: suggestionJson.data_date_range || '-',
        }
      })
    },
    nonRevertedActivities() {
      return this.activities.filter(activity => activity.actionTaken !== 'reverted')
    },
  },
  watch: {
    'context.selected_campaign': {
      handler: function (newVal) {
        if (newVal?.value === null) return
        if (newVal?.value === this.selectedCampaign) return
        if (newVal?.value === 'all-campaigns') {
          this.selectedCampaign = null
          return
        }
        this.selectedCampaign = newVal
        this.selectedActivities = []
      },
    },
  },
  mounted() {
    this.selectedActivities = []
    if (this.context.selected_campaign?.value === 'all-campaigns') {
      this.selectedCampaign = null
      return
    }
    this.selectedCampaign = this.context.selected_campaign
  },
  methods: {
    selectionToggle(type) {
      if (type === 'select-all') {
        this.selectedActivities = this.nonRevertedActivities
      }
      if (type === 'unselect-all') {
        this.selectedActivities = []
      }
    },
    openMetricsPanel(activity) {
      this.metricsPanelActivity = activity
    },
    closeMetricsPanel() {
      this.metricsPanelActivity = null
    },
    clearSelection() {
      this.selectedActivities = []
    },
    selectActivity(activity) {
      const activityIndex = this.selectedActivities.findIndex(kw => kw.id === activity.id)
      if (activityIndex !== -1) {
        this.selectedActivities.splice(activityIndex, 1)
        return
      }
      this.selectedActivities.push(activity)
    },
    openConfirmationModal(undoType) {
      this.modalConfig = {
        size: ConfirmUndoModalSize,
        modal: ConfirmUndoModal,
        undoType: undoType,
      }
    },
    async confirmUndoActivity(undoType) {
      this.$emit('set-page-loading', true, 'ACTIVITY_HISTORY')
      this.modalConfig = null

      switch (undoType) {
        case UNDO_TYPES.UNDO:
          await this.undoActivity(this.selectedActivities)
          break
        case UNDO_TYPES.UNDO_AND_REJECT:
          await this.undoAndRejectActivity(this.selectedActivities)
          break
        case UNDO_TYPES.UNDO_ALL:
          await this.undoAllActivities()
          break
      }

      this.clearSelection()
      this.$emit('refetch-activity-history')
    },
    async undoActivity(activities) {
      const ids = activities.map(activity => activity.id)
      this.$gtm.trackEvent({
        event: 'kw_suggestion_history_undo',
        suggestions: ids.length,
      })
      try {
        const payload = {
          user_email: this.context.user.email,
          ids: ids,
        }
        await this.$http.post(`/search/site/${this.context.site_id}/keyword-suggestions/undo`, payload)
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to undo activity. Please try again later.',
            type: 'error',
          },
        })
      }
    },
    async undoAllActivities() {
      try {
        await this.$http.post(
          `/search/site/${this.context.site_id}/campaign/${this.selectedCampaign.value}/keyword-suggestions/undo-all`,
          {
            user_name: this.context.user_name,
          },
        )
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'Reverting all autopilot suggestions started. This might take some time.',
            type: 'success',
          },
        })
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to undo all the activities. Please try again later.',
            type: 'error',
          },
        })
      }
    },
    async undoAndRejectActivity(activities) {
      console.log('trigger undo and reject', activities) // placeholder
    },
    closeModal() {
      this.modalConfig = null
    },
  },
}
</script>
<style scoped>
.inset {
  inset: 175px 0 0 0;
}
.metrics-panel {
  position: fixed;
  top: 100px;
  right: 0;
  bottom: 0;
  width: 300px;
  min-width: 300px;
  z-index: 20;
  border-left: 1px solid #e5e7eb;
  overflow-y: auto;
}
</style>
