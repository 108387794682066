<template>
  <div ref="brightbid_table">
    <div
      class="overflow-x-auto base-scrollbar bg-white rounded-lg"
      :class="{ 'overflow-y-auto h-full': scrollY }"
      :style="`height: ${isLoading ? maxTableHeight : tableHeight}; max-height: ${maxTableHeight}`"
    >
      <table class="table-class bg-white table-fixed min-w-full">
        <!-- HEADER -->
        <TableHeaderV2
          class="bg-white z-20"
          :class="{ 'sticky top-0': stickyHeader }"
          :headers="visibleHeaders"
          :sorting-order="sortingOrder"
          :last-item-sticky="stickyLastColumn"
          :select-all="true"
          :all-selected="allSelected"
          :partially-selected="selectedItems.length > 0 && selectedItems.length < sortedTableData.length"
          @sort-table="sortTable"
          @select-all="handleSelectAll"
        />

        <!-- BODY -->
        <slot
          v-if="!isLoading"
          name="rows"
          :table-data="tableData"
          :selected-items="selectedItems"
        />

        <!-- BODY LOADING STATE -->
        <tbody
          v-if="isLoading"
          class="h-full"
        >
          <tr>
            <td :colspan="visibleHeaders.length">
              <div>
                <slot name="loader">
                  <div
                    class="flex items-center justify-center"
                    :style="`height: calc(${maxTableHeight} - 80px)`"
                  >
                    <BrightbidLoader size="100" />
                  </div>
                </slot>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- FOOTER -->
    <div
      v-if="data.length > 10"
      class="bg-white rounded-b-md border-t border-neutral-50"
    >
      <div class="flex items-center justify-between h-16 px-8">
        <div>
          <SearchInput
            v-model="rowsPerPage"
            input-name="rows-per-page"
            input-id="rows-per-page"
            :placeholder="rowsPerPage.text"
            :options="rowsPerPageOption"
            dynamic-placeholder
            :required="false"
            :open-below="openPaginationBelow"
            @select-item="changeRowsPerPage"
          />
        </div>
        <div>
          <TablePagination
            :limit="rowsPerPage.value"
            :page="currentPage"
            :max="data.length"
            @onChange="changePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/seamless-scroll-polyfill@latest/lib/bundle.min.js"></script>

<script>
import sortingModule from '@/utils/table/table-sorting'

import TableHeaderV2 from '@/components/table/TableHeaderV2'
import TablePagination from '@/components/table/TablePagination'
import SearchInput from '@/components/input/brightbid/SearchInput'
import BrightbidLoader from '@/components/loader/BrightbidLoader'

const rowsPerPageOption = [
  { label: '10', value: 10, text: 'View 10 per page' },
  { label: '20', value: 20, text: 'View 20 per page' },
  { label: '50', value: 50, text: 'View 50 per page' },
  { label: '100', value: 100, text: 'View 100 per page' },
]

const defaultRowsPerPage = { label: '10', value: 10, text: 'View 10 per page' }

export default {
  name: 'BbTableV2',
  components: {
    TableHeaderV2,
    TablePagination,
    SearchInput,
    BrightbidLoader,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    defaultSortingOrder: {
      type: Object,
      default: () => ({ order: 'ASCENDING', column: null }),
    },
    scrollY: {
      type: Boolean,
      default: false,
    },
    stickyHeader: {
      type: Boolean,
      default: false,
    },
    applyInitialSort: {
      type: Boolean,
      default: true,
    },
    tableHeight: {
      type: String,
      default: 'auto',
    },
    maxTableHeight: {
      type: String,
      default: '',
    },
    openPaginationBelow: {
      type: Boolean,
      default: false,
    },
    stickyLastColumn: {
      type: Boolean,
      default: true,
    },
    selectedItems: {
      // New Prop to Receive Selected Items from Parent
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rowsPerPage: defaultRowsPerPage,
      currentPage: 1,
      paginationData: {},
      sortingOrder: {},
      rowsPerPageOption,
      sortedTableData: [],
    }
  },
  computed: {
    visibleHeaders() {
      return this.headers.filter(header => header.visible)
    },
    tableData() {
      return this.sortedTableData.slice(this.paginationData.from - 1, this.paginationData.to)
    },
    allSelected() {
      return this.selectedItems.length === this.sortedTableData.length && this.sortedTableData.length > 0
    },
  },
  mounted() {
    this.sortedTableData = this.data
    if (this.applyInitialSort) {
      this.setSortingOrder()
      const data = this.headers.find(header => header.value === this.sortingOrder.column)
      if (data) {
        data.sorting = this.sortingOrder.order
        this.sortTable(data)
      }
    }

    // set initial pagination data
    this.setInitialTablePagination()
  },

  watch: {
    data() {
      this.sortedTableData = this.data
      if (this.applyInitialSort) {
        this.setSortingOrder()
        const data = this.headers.find(header => header.value === this.sortingOrder.column)
        if (data) {
          data.sorting = this.sortingOrder.order
          this.sortTable(data)
        }
      }
      // Optionally, reset pagination or selection here
    },
  },
  methods: {
    scrollToTop() {
      this.$nextTick(() => {
        seamless.scrollIntoView(this.$refs.brightbid_table, {
          behavior: 'smooth',
        })
      })
    },

    /*
     * Sorting
     */
    sortTable(data) {
      // Sorting Process
      const sortedData = sortingModule(data, this.sortedTableData)

      // Set sorted data
      this.sortedTableData = sortedData.data
      // Set sorting order
      this.sortingOrder = sortedData.order
      // Set sorting order in headers
      const header = this.headers.find(header => header.value === sortedData.order.column)
      if (header) {
        header.sorting = sortedData.order.order
      }
    },
    setSortingOrder() {
      this.sortingOrder = {
        column: this.defaultSortingOrder.column ?? (this.headers[0] && this.headers[0].value),
        order: this.defaultSortingOrder.order,
      }
    },

    /*
     * Pagination
     */
    setInitialTablePagination() {
      this.paginationData = {
        current_page: 1,
        from: 1,
        to: this.rowsPerPage.value,
        per_page: this.rowsPerPage.value,
      }
    },
    changePage(paginationData) {
      this.currentPage = paginationData.current_page
      this.paginationData = paginationData
      // this.scrollToTop()
    },
    changeRowsPerPage(item) {
      this.rowsPerPage = item
      this.currentPage = 1
      this.paginationData = {
        current_page: 1,
        from: 1,
        to: this.rowsPerPage.value,
        per_page: this.rowsPerPage.value,
      }
      // this.scrollToTop()
    },

    /*
     * Selection
     */
    handleSelectAll(checked) {
      // Handle "Select All" Toggle
      this.$emit('select-all', checked)
    },
  },
}
</script>
