export const HUB_ENVIRONMENT = {
  PRODUCTION: 'production',
  BETA: 'beta',
  DEVELOPMENT: 'development',
}

export const PERMISSIONS = {
  VIEW: 'view',
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
}

export const RESOURCES = {
  ORGANIZATION: 'organization',
  SITE: 'site',
  CHANNEL: 'channel',
  API_TOKEN: 'api_token',
  PERMISSION: 'permission',
  STANDARD_USER: 'standard_user',
  ACCESS_STANDARD_USER_TO_ORGANIZATION: 'access_standard_user_to_organization',
  USER_ROLE_LEVEL: 'user_role_level',
  USER: 'user',
  USER_INVITE: 'user_invite',
}

export const CHANNELS = {
  SHOPPING_STANDARD: 'shopping_standard',
  SHOPPING_AMAZON: 'shopping_amazon',
  SEARCH_GOOGLE: 'search_google',
  SEARCH_BING: 'search_bing',
}

export const BID_STRATEGIES = [
  {
    value: 'BRIGHTBID_AI',
    label: 'Brightbid AI',
    disabled: false,
    icon: 'solid-brightbid-logo',
    requiredTarget: false,
  },
  {
    value: 'GOOGLE_MAXIMIZE_CLICKS',
    label: 'Google Maximize Clicks',
    disabled: false,
    icon: 'google-g-icon',
    requiredTarget: false,
  },
  {
    value: 'GOOGLE_MAXIMIZE_CONVERSIONS',
    label: 'Google Maximize Conversions',
    disabled: false,
    icon: 'google-g-icon',
    requiredTarget: false,
  },
  {
    value: 'GOOGLE_MAXIMIZE_CONVERSION_VALUE',
    label: 'Google Maximize Conversion Value',
    disabled: false,
    icon: 'google-g-icon',
    requiredTarget: false,
  },
  {
    value: 'GOOGLE_TARGET_CPA',
    label: 'Google Target CPA',
    disabled: false,
    icon: 'google-g-icon',
    requiredTarget: true,
  },
  {
    value: 'GOOGLE_TARGET_ROAS',
    label: 'Google Target ROAS',
    disabled: false,
    icon: 'google-g-icon',
    requiredTarget: true,
  },
  {
    value: 'PERFORMANCE_MAX',
    label: 'Performance Max',
    disabled: true,
    icon: 'ic-pmax',
    requiredTarget: false,
  },
]

import { enums } from 'google-ads-api/build/src/protos/autogen/enums.js'
const { BiddingStrategyType } = enums

export const BID_STRATEGIES_TARGET_OPTIONS = {
  CPA: {
    label: 'Target CPA',
    value: 'CPA',
    unit: 'currency',
  },
  ROAS: {
    label: 'Target ROAS',
    value: 'ROAS',
    unit: 'percentage',
  },
  CPC: {
    label: 'Max CPC',
    value: 'CPC',
    unit: 'currency',
  },
  TARGET_CPA: {
    label: 'Target CPA',
    value: BiddingStrategyType[BiddingStrategyType.TARGET_CPA],
    unit: 'currency',
  },
  TARGET_ROAS: {
    label: 'Target ROAS',
    value: BiddingStrategyType[BiddingStrategyType.TARGET_ROAS],
    unit: 'percentage',
  },
  MAX_CPC: {
    label: 'Max CPC',
    value: BiddingStrategyType[BiddingStrategyType.TARGET_SPEND],
    unit: 'currency',
  },
  MAXIMIZE_CONVERSIONS: {
    label: 'Target CPA',
    value: BiddingStrategyType[BiddingStrategyType.MAXIMIZE_CONVERSIONS],
    unit: 'currency',
  },
  MAXIMIZE_CONVERSION_VALUE: {
    label: 'Target ROAS',
    value: BiddingStrategyType[BiddingStrategyType.MAXIMIZE_CONVERSION_VALUE],
    unit: 'percentage',
  },
}

export const BID_STRATEGIES_TARGET_OPTIONS_MAPPING = {
  BRIGHTBID_AI: [
    BID_STRATEGIES_TARGET_OPTIONS.CPA,
    BID_STRATEGIES_TARGET_OPTIONS.ROAS,
    BID_STRATEGIES_TARGET_OPTIONS.CPC,
  ],
  GOOGLE_MAXIMIZE_CLICKS: [BID_STRATEGIES_TARGET_OPTIONS.MAX_CPC],
  GOOGLE_TARGET_CPA: [BID_STRATEGIES_TARGET_OPTIONS.TARGET_CPA],
  GOOGLE_TARGET_ROAS: [BID_STRATEGIES_TARGET_OPTIONS.TARGET_ROAS],
  GOOGLE_MAXIMIZE_CONVERSIONS: [BID_STRATEGIES_TARGET_OPTIONS.MAXIMIZE_CONVERSIONS],
  GOOGLE_MAXIMIZE_CONVERSION_VALUE: [BID_STRATEGIES_TARGET_OPTIONS.MAXIMIZE_CONVERSION_VALUE],
  PERFORMANCE_MAX: [
    BID_STRATEGIES_TARGET_OPTIONS.MAXIMIZE_CONVERSIONS,
    BID_STRATEGIES_TARGET_OPTIONS.MAXIMIZE_CONVERSION_VALUE,
  ],
}

export const BID_STRATEGIES_TARGET_TYPES = {
  CPA: 'CPA',
  ROAS: 'ROAS',
  CPC: 'CPC',
  TARGET_SPEND: 'CPC',
  TARGET_CPA: 'CPA',
  TARGET_ROAS: 'ROAS',
  MAXIMIZE_CONVERSIONS: 'CPA',
  MAXIMIZE_CONVERSION_VALUE: 'ROAS',
}

export const METRIC_LABEL_MAPPING = {
  ctr: 'Click-Through Rate',
  cpc: 'Cost Per Click',
  cvr: 'Conversion Rate',
  cpa: 'Cost Per Action',
  avg_cart: 'Average Conversion Value',
  clicks: 'Clicks',
  impressions: 'Impressions',
  conversions: 'Conversions',
  cost: 'Cost',
}

export const INDUSTRIES = [
  'Aerospace and Defense',
  'Agriculture and Farming',
  'Architecture and Interior Design',
  'Art, Design, and Photography',
  'Automotive',
  'Beauty and Personal Care',
  'Blockchain and Cryptocurrency',
  'Chemicals and Materials',
  'Clothing, Fashion, and Apparel',
  'Construction and Engineering',
  'Consulting and Business Services',
  'Consumer Goods and Services',
  'Digital Media and Online Content',
  'E-commerce',
  'Education (Traditional and Online)',
  'Energy and Utilities',
  'Entertainment and Media',
  'Environmental and Sustainability',
  'Esports and Competitive Gaming',
  'Event Planning and Services',
  'Finance and Banking',
  'Fintech',
  'Fitness and Wellness',
  'Food and Beverage',
  'Government and Public Administration',
  'Green Technology',
  'Healthcare and Medicine',
  'Home and Garden',
  'Human Resources and Employment Services',
  'Information Technology and Services',
  'Legal and Compliance',
  'Manufacturing',
  'Marketing and Advertising',
  'Metals and Mining',
  'Music and Performing Arts',
  'Nonprofit and Social Services',
  'Outsourcing and Offshoring',
  'Packaging and Containers',
  'Pharmaceuticals and Biotech',
  'Plastics and Rubber',
  'Professional Training and Coaching',
  'Publishing and Printing',
  'Real Estate',
  'Religion and Spirituality',
  'Remote Work Solutions',
  'Retail (Online and Brick-and-Mortar)',
  'SaaS and Cloud Services',
  'Science and Research',
  'Security and Investigations',
  'Sports and Fitness',
  'Subscription Services',
  'Technology (Hardware, Software, IT Services)',
  'Telecommunications',
  'Transportation and Logistics',
  'Travel and Hospitality',
  'Veterinary and Pet Services',
  'Waste Management',
]
