<template>
  <div class="w-full h-full p-6 pb-20 space-y-6 relative responsive-section">
    <h3 class="py-2">Overview</h3>

    <div
      v-if="false"
      class="flex h-full items-center justify-center absolute top-0 mx-auto w-full inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
    >
      <brightbid-loader />
    </div>

    <!--ROW 1-->
    <div class="flex flex-col lg:flex-row gap-6">
      <!--YOUR SUMMARY-->
      <summary-card
        title="Your Summary"
        :summary="mySummary"
      >
        <template #icon>
          <div class="bg-neutral-0 h-8 w-8 rounded-lg text-bb-brand-purple flex items-center justify-center">
            <ic-one-line-chart />
          </div>
        </template>
        <template #summary="{ summary }">
          <div>
            <div class="flex items-center gap-2">
              <p>{{ summary.label }}</p>
            </div>

            <h2>{{ summary.value }}</h2>
          </div>
        </template>
      </summary-card>

      <!--COMPETITOR SUMMARY-->
      <summary-card
        title="Competitor Summary"
        :summary="summaryData"
      >
        <template #icon>
          <div class="bg-neutral-0 h-8 w-8 rounded-lg text-bb-brand-purple flex items-center justify-center">
            <ic-two-line-chart />
          </div>
        </template>
        <template #summary="{ summary }">
          <div class="text-center">
            <div class="flex items-center gap-2">
              <div
                class="rounded-full flex items-center justify-center h-8 w-8"
                :class="summary.iconClass"
              >
                <div>
                  <component :is="summary.icon" />
                </div>
              </div>
              <p>{{ summary.label }}</p>
            </div>

            <h2>{{ summary.value }}</h2>
          </div>
        </template>
      </summary-card>
    </div>

    <!--ROW 2-->
    <div class="flex gap-6 w-full">
      <div class="w-full">
        <top-competitors
          :top-competitors="top10CompetitorsByImpressionShare"
          @see-all-competitors="seeAllCompetitors"
        />
      </div>
    </div>

    <!--ROW 3-->
    <div class="flex flex-col lg:flex-row gap-6 w-full">
      <div class="w-1/2 flex flex-col justify-between">
        <notification-card
          class="h-40 border border-neutral-50 purple-gradient text-white"
          card-class="border-bb-brand-purple p-5"
          card-title="Smart Activation"
        >
          <template #card-icon>
            <div class="text-bb-brand-purple bg-white rounded-full p-1.5">
              <ic-magic-wand :size="24" />
            </div>
          </template>
          <template #right-icon>
            <div>
              <ic-chevron
                direction="right"
                :size="40"
              />
            </div>
          </template>
          <template #card-content>
            <div class="text-sm space-y-1">
              <p>You have saved</p>
              <div class="flex items-center gap-4">
                <div class="flex items-center gap-1">
                  <ic-clock :size="16" />
                  <h4 class="h4">{{ totalHoursSaved }} Hours</h4>
                </div>
                <div class="flex items-center gap-1">
                  <ic-dollar :size="16" />
                  <h4 class="h4">{{ totalMoneySaved }}</h4>
                </div>
              </div>

              <!-- TODO: fetch N-Campaigns and N-Keywords -->
              <!--<p>Enabled on n campaigns (n keywords)</p>-->
            </div>
          </template>
        </notification-card>
        <notification-card
          class="h-40 border border-neutral-50"
          card-class="border-bb-red-error p-5"
          card-title="Infringements"
        >
          <template #card-icon>
            <div class="text-bb-red-error bg-bb-red-50 rounded-full p-1.5">
              <ic-warning :size="24" />
            </div>
          </template>
          <template #right-icon>
            <div class="text-bb-text-secondary">
              <ic-chevron
                direction="right"
                :size="40"
              />
            </div>
          </template>
          <template #card-content>
            <div class="text-sm space-y-1">
              <h5 class="h5">{{ infringementSummary?.newCount ?? 0 }} new violations on your brand</h5>
              <p class="text-bb-text-secondary">Monitoring 1 campaign</p>
            </div>
          </template>
        </notification-card>
      </div>
      <div class="w-1/2">
        <top-of-page-chart :competition-ranking="topOfPage" />
      </div>
    </div>

    <!--ROW 4-->
    <div class="flex gap-6 w-full h-100">
      <div class="w-full">
        <most-frequent-rank :most-frequent-rank="mostFrequentRank || {}" />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import TopCompetitors from '@/views/site/search/competitor_monitoring_v2/overview/cards/TopCompetitors.vue'
import SummaryCard from '@/views/site/search/competitor_monitoring_v2/overview/cards/SummaryCard.vue'
import TopOfPageChart from '@/views/site/search/competitor_monitoring_v2/overview/cards/TopOfPageChart.vue'
import MostFrequentRank from '@/views/site/search/competitor_monitoring_v2/overview/cards/MostFrequentRank.vue'
import NotificationCard from '@/views/site/search/competitor_monitoring_v2/overview/cards/NotificationCard.vue'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'

// Icons
import IcOneLineChart from '@/components/icon/brightbid/ic-one-line-chart.vue'
import IcTwoLineChart from '@/components/icon/brightbid/ic-two-line-chart.vue'
import IcNormalPeople from '@/components/icon/brightbid/ic-normal-people.vue'
import IcPositivePeople from '@/components/icon/brightbid/ic-positive-people.vue'
import IcNegativePeople from '@/components/icon/brightbid/ic-negative-people.vue'
import IcChevron from '@/components/icon/ic-chevron.vue'
import IcMagicWand from '@/components/icon/brightbid/ic-magic-wand.vue'
import IcWarning from '@/components/icon/brightbid/ic-warning.vue'
import IcClock from '@/components/icon/brightbid/IcClock.vue'
import IcDollar from '@/components/icon/ic-dollar.vue'

import { mapActions, mapState, mapGetters } from 'vuex'
import { getWebsiteFromURL } from '@/utils/url-util'

export default {
  name: 'OverviewV2',
  components: {
    BrightbidLoader,
    TopOfPageChart,
    MostFrequentRank,
    TopCompetitors,
    SummaryCard,
    NotificationCard,

    IcOneLineChart,
    IcTwoLineChart,
    IcNormalPeople,
    IcPositivePeople,
    IcNegativePeople,
    IcChevron,
    IcMagicWand,
    IcWarning,
    IcClock,
    IcDollar,
  },
  data() {
    return {}
  },

  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('competitorMonitoringV2', [
      'selectedMonitoringGroup',
      'insights',
      'competitorSummary',
      'savingsInsights',
      'infringementSummary',
      'mostFrequentRank',
    ]),
    ...mapGetters('competitorMonitoringV2', ['top10CompetitorsByImpressionShare', 'competitorsByAbsoluteTopOfPage']),
    summaryData() {
      return [
        {
          label: 'Competitors',
          value: this.competitorSummary ? this.competitorSummary.totalCompetitors : '-',
          icon: 'ic-normal-people',
          iconClass: 'text-bb-brand-purple bg-bb-purple-50',
        },
        {
          label: 'New Entrants',
          value: this.competitorSummary ? this.competitorSummary.entered : '-',
          icon: 'ic-positive-people',
          iconClass: 'text-bb-red-error bg-bb-red-50 ml-2',
        },
        {
          label: 'Exits',
          value: this.competitorSummary ? this.competitorSummary.left : '-',
          icon: 'ic-negative-people',
          iconClass: 'text-bb-blue bg-bb-light-blue ml-2',
        },
      ]
    },
    myInsight() {
      const myName = getWebsiteFromURL(this.selectedSite.url).toLowerCase()
      if (!myName || !this.insights) return null
      return this.insights.find(insight => insight?.competitorName.toLowerCase() === myName)
    },
    mySummary() {
      if (!this.insights)
        return [
          { label: 'Impressions share', value: '-' },
          { label: 'Abs. top of page', value: '-' },
          { label: 'Avg position', value: '-' },
        ]

      return [
        { label: 'Impressions share', value: this.myInsight ? `${this.myInsight.impressionShare}%` : '-' },
        { label: 'Abs. top of page', value: this.myInsight ? `${this.myInsight.absoluteTopOfPage}%` : '-' },
        { label: 'Avg position', value: this.myInsight ? `${this.myInsight.averagePosition}` : '-' },
      ]
    },
    totalHoursSaved() {
      if (!this.savingsInsights) return 0
      return parseFloat((this.savingsInsights.totalTimeSavedSeconds / 3600).toFixed(2))
    },
    totalMoneySaved() {
      if (!this.savingsInsights) return 0
      return `${this.savingsInsights.totalMoneySaved.value} ${this.savingsInsights.totalMoneySaved.currency}`
    },
    topOfPage() {
      if (!this.competitorsByAbsoluteTopOfPage) return []

      const myName = this.myInsight?.competitorName
      return this.competitorsByAbsoluteTopOfPage.map(competitor => {
        return {
          isOwn: myName === competitor.competitorName,
          competitorName: competitor.competitorName,
          topPagePercentage: competitor.absoluteTopOfPage,
        }
      })
    },
  },
  methods: {
    ...mapActions('toast', ['loadToast']),
    seeAllCompetitors() {
      console.log('placeholder: see all competitors')
    },
  },
}
</script>

<style scoped lang="scss">
.container-height {
  min-height: calc(100vh - 200px);
}

.purple-gradient {
  background: linear-gradient(103.71deg, #9496ff -20.18%, #6366fa 64.13%);
}
</style>
