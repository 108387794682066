<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="33"
      height="33"
      rx="5.5"
      stroke="#F0F1F3"
    />
    <path
      d="M21 19V21H13V19H11.6667V21C11.6667 21.7333 12.2667 22.3333 13 22.3333H21C21.7334 22.3333 22.3334 21.7333 22.3334 21V19H21ZM20.3334 16.3333L19.3934 15.3933L17.6667 17.1133V11.6666H16.3334V17.1133L14.6067 15.3933L13.6667 16.3333L17 19.6666L20.3334 16.3333Z"
      fill="#6D7883"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcDownload',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
  },
}
</script>

<style scoped lang="scss"></style>
