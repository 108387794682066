<template>
  <div
    class="lds-ellipsis"
    :style="{
      '--loader-size': size + 'px',
    }"
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'LoaderEllipsis',
  props: {
    size: {
      type: Number,
      default: 13, // Default size is 13px
    },
  },
}
</script>

<style lang="scss" scoped>
$speed: 0.7s;

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: calc(6 * var(--loader-size));
  height: var(--loader-size);
}

.lds-ellipsis div {
  position: absolute;
  top: 0;
  width: var(--loader-size);
  height: var(--loader-size);
  border-radius: 50%;
  background: currentColor;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: calc(var(--loader-size) / 1.5);
  animation: lds-ellipsis1 $speed infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: calc(var(--loader-size) / 1.5);
  animation: lds-ellipsis2 $speed infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: calc(3 * var(--loader-size));
  animation: lds-ellipsis2 $speed infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: calc(5 * var(--loader-size));
  animation: lds-ellipsis3 $speed infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(calc(2 * var(--loader-size)), 0);
  }
}
</style>
