<template>
  <div>
    <!--TABLE-->
    <div class="w-full px-6 pb-10">
      <div class="border rounded-lg overflow-hidden bb-default-shadow">
        <div class="overflow-y-auto base-scrollbar">
          <table class="w-full">
            <table-header
              :headers="headers"
              :sorting-order="sortingOrder"
              class="sticky top-0 bg-white z-10"
              @sort-table="sortTable"
            />
            <all-competitors-table-rows :all-competitors="sortedTableData" />
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableHeader from '@/components/table/TableHeader.vue'
import AllCompetitorsTableRows from '@/views/site/search/competitor_monitoring_v2/competitors/table/competitors/AllCompetitorsTableRows.vue'
import sortingModule from '@/utils/table/table-sorting'

const defaultSortingOrder = { order: 'ASCENDING', column: 'shareOfSearchPercentage' }

export default {
  name: 'AllCompetitorsTable',
  components: { TableHeader, AllCompetitorsTableRows },
  props: {
    allCompetitors: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          value: 'competitorName',
          label: 'Competitor',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'adVariationsCount',
          label: 'Ad Variations',
          sortable: true,
          sorting: null,
          position: 'center',
        },
        {
          value: 'topPagePercentage',
          label: 'Top of page %',
          sortable: true,
          sorting: null,
          position: 'center',
        },
        {
          value: 'avgAdPosition',
          label: 'Avg. Ad Position',
          sortable: true,
          sorting: null,
          position: 'center',
        },
        {
          value: 'shareOfSearchPercentage',
          label: 'Share of Ad Impressions',
          sortable: true,
          sorting: null,
          position: 'center',
        },
        {
          value: 'action',
          label: '',
          sortable: false,
          sorting: null,
          position: 'right',
        },
      ],
      sortingOrder: defaultSortingOrder,
      sortedTableData: [],
      isLoading: false,
      touchedExpressions: [],
    }
  },
  watch: {
    allCompetitors: {
      immediate: true,
      handler(newVal) {
        const tableData = structuredClone(newVal)
        this.sortedTableData = tableData.map(item => {
          return {
            ...item,
            adVariationsCount: item.adVariations.length || 0,
          }
        })
      },
    },
  },
  mounted() {
    const data = this.headers.find(header => header.value === this.sortingOrder.column)
    data.sorting = this.sortingOrder.order
    this.sortTable(data)
  },
  methods: {
    sortTable(data) {
      // Sorting Process
      const sortedData = sortingModule(data, this.sortedTableData)

      // Set sorted data
      this.sortedTableData = sortedData.data
      // Set sorting order
      this.sortingOrder = sortedData.order
      // Set sorting order in headers
      this.headers.find(header => header.value === sortedData.order.column).sorting = sortedData.order.order
    },
  },
}
</script>

<style scoped lang="scss"></style>
