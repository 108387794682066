<template>
  <div>
    <div class="responsive-section">
      <div class="w-full min-h-screen p-6 space-y-6 relative">
        <div class="space-y-1">
          <h3 class="py-2">Infringements</h3>
          <p class="text-sm text-bb-text-default font-normal">
            Detect competitors using your monitored keyword terms in their ad copy
          </p>
        </div>

        <div
          v-if="false"
          class="flex h-full items-center justify-center absolute top-0 mx-auto w-full inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
        >
          <brightbid-loader />
        </div>
        <div>
          <div
            v-if="hasInfringementSummary"
            class="pb-10"
          >
            <infringement-summary
              :infringement-summary="infringementSummary"
              @open-whitelist-modal="openWhitelistModal"
            />
          </div>
          <div>
            <all-infringements-tab :infringement-count="allInfringements.length" />
            <infringement-table
              :infringements="allInfringements"
              :is-table-loading="isTableLoading"
              :table-loading-message="tableLoadingMessage"
              @infringement-action="handleInfringementAction($event, 'table')"
              @open-infringement-summary="openInfringementSummaryModal"
            />
          </div>
        </div>
      </div>
    </div>
    <bb-base-modal
      v-if="modalConfig.modal"
      :width="modalConfig.width"
      :height="modalConfig.height"
      @close="closeModal"
    >
      <template #container>
        <competitor-infringement-summary-modal
          v-if="modalConfig.modal === 'infringementSummary'"
          :infringements="selectedMoreInfringements"
          :competitor-url="selectedInfringement.competitorUrl"
          :competitor-name="selectedInfringement.competitorName"
          :is-modal-loading="isInfringementModalLoading"
          :loading-message="modalLoadingMessage"
          @infringement-action="handleInfringementAction($event, 'modal')"
          @close="closeModal"
        />
        <whitelist-modal
          v-if="modalConfig.modal === 'whitelist'"
          :whitelist="whitelist ?? []"
          :is-adding-infringement="isAddingInfringement"
          :touched-whitelist-url="touchedWhitelistUrl"
          @add-whitelist="addWhitelist"
          @edit-whitelist="editWhitelist"
          @remove-whitelist="removeWhitelist"
          @close="closeModal"
        />
      </template>
    </bb-base-modal>
  </div>
</template>

<script>
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'
import InfringementSummary from '@/views/site/search/competitor_monitoring_v2/infringements/InfringementSummary.vue'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'
import WhitelistModal from '@/views/site/search/competitor_monitoring_v2/infringements/whitelist/WhitelistModal.vue'
import AllInfringementsTab from '@/views/site/search/competitor_monitoring_v2/infringements/all_infringements/AllInfringementsTab.vue'
import InfringementTable from '@/views/site/search/competitor_monitoring_v2/infringements/all_infringements/InfringementTable.vue'
import CompetitorInfringementSummaryModal from '@/views/site/search/competitor_monitoring_v2/infringements/competitor_infringement_summary/CompetitorInfringementSummaryModal.vue'
import Toast from '@/components/shared/Toast'
import { mapActions, mapState } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'InfringementsDetector',
  components: {
    InfringementSummary,
    AllInfringementsTab,
    InfringementTable,
    BbBaseModal,
    WhitelistModal,
    BrightbidLoader,
    CompetitorInfringementSummaryModal,
  },
  data() {
    return {
      isTableLoading: false,
      isInfringementModalLoading: false,
      isAddingInfringement: false,
      tableLoadingMessage: '',
      modalLoadingMessage: '',
      modalConfig: { width: '', height: '', modal: null },
      selectedInfringement: null,
      touchedWhitelistUrl: {},

      isMonitoringJobStopped: false, // TODO: get from store
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('competitorMonitoringV2', [
      'infringementSummary',
      'allInfringements',
      'selectedMonitoringGroup',
      'infringementDetails',
      'dateRange',
      'whitelist',
    ]),
    hasInfringementSummary() {
      if (!this.infringementSummary) return false
      return Object.keys(this.infringementSummary).length !== 0
    },
    selectedMoreInfringements() {
      const infringements = this.infringementDetails[this.selectedInfringement.competitorName]
      return infringements || []
    },
    isMonitoringGroupStopped() {
      return this.selectedMonitoringGroup?.id && !this.selectedMonitoringGroup?.initialized
    },
    validDateRange() {
      const end = this.isMonitoringGroupStopped
        ? this.selectedMonitoringGroup?.updatedAt ?? this.dateRange[1]
        : this.dateRange[1]

      const startDate = dayjs(this.dateRange[0]).format('YYYY-MM-DD')
      const endDate = dayjs(end).format('YYYY-MM-DD')

      return { startDate, endDate }
    },
  },
  methods: {
    ...mapActions('toast', ['loadToast']),
    ...mapActions('competitorMonitoringV2', [
      'loadInfringementDetails',
      'loadAllInfringements',
      'loadInfringementSummary',
      'loadWhitelist',
    ]),

    // Loading
    setLoadingState(component, action, isLoading) {
      if (component === 'table') {
        this.isTableLoading = isLoading
        if (action === 'resolve' && isLoading) {
          this.tableLoadingMessage = 'Resolving infringement...'
        } else if (action === 'whitelist' && isLoading) {
          this.tableLoadingMessage = 'Whitelisting infringement...'
        } else {
          this.tableLoadingMessage = ''
        }
      } else if (component === 'modal') {
        this.isInfringementModalLoading = isLoading
        if (action === 'resolve' && isLoading) {
          this.modalLoadingMessage = 'Resolving infringement...'
        } else if (action === 'whitelist' && isLoading) {
          this.modalLoadingMessage = 'Whitelisting infringement...'
        } else {
          this.modalLoadingMessage = ''
        }
      }
    },

    async getInfringements() {
      await this.loadAllInfringements({
        siteId: this.selectedSite.value,
        monitoringGroupId: this.selectedMonitoringGroup.id,
        startDate: this.validDateRange.startDate,
        endDate: this.validDateRange.endDate,
        fullReload: true,
      })
    },
    async getInfringementDetails(competitorName) {
      await this.loadInfringementDetails({
        siteId: this.selectedSite.value,
        monitoringGroupId: this.selectedMonitoringGroup.id,
        startDate: this.validDateRange.startDate,
        endDate: this.validDateRange.endDate,
        competitorName: competitorName,
        fullReload: true,
      })
    },
    async getInfringementsSummary() {
      await this.loadInfringementSummary({
        siteId: this.selectedSite.value,
        monitoringGroupId: this.selectedMonitoringGroup.id,
        startDate: this.validDateRange.startDate,
        endDate: this.validDateRange.endDate,
        fullReload: true,
      })
    },
    async getWhitelist() {
      await this.loadWhitelist({
        siteId: this.selectedSite.value,
        monitoringGroupId: this.selectedMonitoringGroup.id,
        fullReload: true,
      })
    },

    async handleInfringementAction(data, component) {
      if (this.isMonitoringJobStopped) {
        await this.loadToast({
          title: 'Warning',
          message: `You're not allowed to perform a ${data.action} action while the monitoring job is paused.`,
          type: 'warning',
        })
        return
      }

      const infringement = this.allInfringements.find(item => item.competitorName === data.competitorName)

      this.setLoadingState(component, data.action, true)
      const tasks = []

      switch (data.action) {
        case 'resolve':
          await this.resolveInfringement(data)
          tasks.push(this.getInfringements())
          tasks.push(this.getInfringementDetails(data.competitorName))
          break
        case 'mark-all-as-solved':
          await this.resolveAllInfringements(data.competitorName)
          tasks.push(this.getInfringements())
          tasks.push(this.getInfringementDetails(data.competitorName))
          break
        case 'whitelist':
          await this.whitelistInfringement(infringement)
          if (component === 'table') {
            this.$toast.success({
              component: Toast,
              props: {
                title: `Nice! ${infringement.competitorUrl} has been added to your white list.`,
                message: 'From now on you will not receive any infringement alerts from this competitor.',
                type: 'success',
                hasAButton: true,
                buttonText: 'Check list',
                buttonType: 'primary',
                buttonClass: 'w-30',
              },
              listeners: {
                click: () => {
                  this.openWhitelistModal()
                },
              },
            })
          }
          tasks.push(this.getInfringements())
          tasks.push(this.getInfringementsSummary())
          tasks.push(this.getWhitelist())
          break
      }

      await Promise.all(tasks)
      this.setLoadingState(component, data.action, false)
      if (data.action === 'whitelist' && component === 'modal') this.closeModal()
    },
    async resolveInfringement(infringement) {
      const filter = {
        competitorName: infringement.competitorName,
        competitorUrl: infringement.competitorUrl,
        title: infringement.title,
        description: infringement.description,
      }
      try {
        await this.$http.post(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.selectedMonitoringGroup.id}/infringement/resolve/v2`,
          { filters: [filter] },
        )
        await this.loadToast({
          title: 'Success',
          message: `Successfully resolved infringement with ${infringement.competitorName}.`,
          type: 'success',
        })
      } catch (e) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to resolve infringement.',
          type: 'error',
        })
      }
    },
    async resolveAllInfringements(competitorName) {
      try {
        await this.$http.post(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.selectedMonitoringGroup.id}/infringement/resolve-all/v2`,
          { competitorName },
        )
        await this.loadToast({
          title: 'Success',
          message: `Successfully resolved all infringements with ${competitorName}.`,
          type: 'success',
        })
      } catch (e) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to resolve all infringements.',
          type: 'error',
        })
      }
    },

    async whitelistInfringement(infringement) {
      try {
        await this.$http.post(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.selectedMonitoringGroup.id}/infringement/whitelist`,
          {
            url: infringement.competitorUrl,
          },
        )
      } catch (e) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to whitelist infringement.',
          type: 'error',
        })
      }
    },
    async openInfringementSummaryModal(item) {
      this.selectedInfringement = { competitorName: item.competitorName, competitorUrl: item.competitorUrl }
      this.modalConfig = { width: '950px', height: '750px', modal: 'infringementSummary' }
      if (Object.prototype.hasOwnProperty.call(this.infringementDetails, item.competitorName)) {
        return
      }

      this.isInfringementModalLoading = true
      await this.getInfringementDetails(item.competitorName)
      this.isInfringementModalLoading = false
    },

    openWhitelistModal() {
      this.modalConfig = { width: '600px', height: '600px', modal: 'whitelist' }
    },
    async addWhitelist(whitelistUrl) {
      if (this.isMonitoringJobStopped) {
        await this.loadToast({
          title: 'Warning',
          message: `You're not allowed to perform a whitelist action while the monitoring job is paused.`,
          type: 'warning',
        })
        return
      }

      try {
        this.isAddingInfringement = true
        await this.$http.post(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.selectedMonitoringGroup.id}/infringement/whitelist`,
          {
            url: whitelistUrl,
          },
        )
        await Promise.all([this.getWhitelist(), this.getInfringements(), this.getInfringementsSummary()])
      } catch (e) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to add to whitelist.',
          type: 'error',
        })
      } finally {
        this.isAddingInfringement = false
      }
    },
    async editWhitelist(whitelist) {
      if (this.isMonitoringJobStopped) {
        await this.loadToast({
          title: 'Warning',
          message: `You're not allowed to perform a whitelist action while the monitoring job is paused.`,
          type: 'warning',
        })
        return
      }

      try {
        this.touchedWhitelistUrl[whitelist.id] = { ...whitelist, action: 'edit' }
        await this.$http.patch(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.selectedMonitoringGroup.id}/infringement/whitelist/${whitelist.id}`,
          { url: whitelist.url },
        )
        await Promise.all([this.getWhitelist(), this.getInfringements()])
      } catch (e) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to edit whitelist.',
          type: 'error',
        })
      } finally {
        delete this.touchedWhitelistUrl[whitelist.id]
      }
    },
    async removeWhitelist(whitelist) {
      if (this.isMonitoringJobStopped) {
        await this.loadToast({
          title: 'Warning',
          message: `You're not allowed to perform a whitelist action while the monitoring job is paused.`,
          type: 'warning',
        })
        return
      }

      try {
        this.touchedWhitelistUrl[whitelist.id] = { ...whitelist, action: 'remove' }
        await this.$http.delete(
          `/cms/site/${this.selectedSite.value}/monitoring-job/${this.selectedMonitoringGroup.id}/infringement/whitelist/${whitelist.id}`,
        )
        await Promise.all([this.getWhitelist(), this.getInfringements(), this.getInfringementsSummary()])
      } catch (e) {
        await this.loadToast({
          title: 'Error',
          message: 'Failed to remove expression. Please try again.',
          type: 'error',
        })
      } finally {
        delete this.touchedWhitelistUrl[whitelist.id]
      }
    },

    closeModal() {
      this.selectedInfringement = null
      this.modalConfig = { width: '', height: '', modal: null }
    },
  },
}
</script>

<style scoped lang="scss"></style>
