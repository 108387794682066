<template>
  <div class="relative px-6">
    <div class="max-w-200 space-y-3">
      <h4 class="h4 pt-10">Autopilot Settings</h4>
      <p class="text-sm">
        Choose which campaigns will use the keyword suggestions tool and Autopilot. Enabled campaigns undergo analysis
        to provide relevant keywords. Autopilot automatically accepts suggestions. All actions are reversible
      </p>
    </div>
    <div class="flex justify-between items-center pt-4">
      <div class="flex justify-start gap-6 text-sm font-medium">
        <p>All Campaigns: {{ context.campaigns.length }}</p>
        <p>Autopilot: {{ autopilotLength }}</p>
      </div>
      <div class="flex flex-row gap-x-1">
        <div class="flex h-14 justify-between items-center font-medium">
          <!-- search here -->
          <text-input
            v-model="query"
            class="w-70"
            input-name="search"
            input-id="search"
            input-type="text"
            placeholder="Search Campaign or Ad Group"
            padding-left="pl-10"
          >
            <template #prefix>
              <div class="absolute z-10 h-10 p-2 text-bb-disabled-gray">
                <IcSearch />
              </div>
            </template>
          </text-input>
        </div>
        <UpdateCampaignButton
          :site-id="context.site_id"
          @set-page-loading="$emit('set-page-loading', true, 'CAMPAIGN_SETTINGS')"
        ></UpdateCampaignButton>
      </div>
    </div>
    <!--TABLE-->
    <div class="w-full mt-4 mb-20">
      <div class="border rounded-lg overflow-hidden">
        <table class="w-full">
          <table-header
            :headers="headers"
            :sorting-order="sortingOrder"
            class="sticky top-0 bg-white z-10"
            @sort-table="sortTable"
          />
          <ai-campaign-rows
            ref="aiCampaignRows"
            :data="campaignTableRows"
            @update-status="updateStatus"
            @toggle-autopilot-category="toggleAutopilotCategory"
          />
        </table>
      </div>
    </div>
    <!--SAVE AND CANCEL BUTTONS-->
    <div class="flex items-center justify-end py-6 bg-white sticky bottom-0 z-10">
      <div class="flex gap-x-6 items-center">
        <merge-button-round
          button-type="secondary"
          class="w-30"
          @click="cancel"
        >
          <p>Cancel</p>
        </merge-button-round>
        <merge-button-round
          button-type="primary"
          class="w-30"
          @click="save"
        >
          <p>Save</p>
        </merge-button-round>
      </div>
    </div>
  </div>
</template>

<script>
import { SWITCH_STATUS } from '@/utils/enums'
import sortingModule from '@/utils/table/table-sorting'
import Fuse from 'fuse.js'

import TextInput from '@/components/input/brightbid/TextInput.vue'
import AiCampaignRows from '@/components/table/table_rows/AiCampaignRows.vue'
import TableHeader from '@/components/table/TableHeader.vue'
import Toast from '@/components/shared/Toast.vue'
import UpdateCampaignButton from '@/components/btn/UpdateCampaignButton.vue'

import IcSearch from 'vue-material-design-icons/Magnify.vue'

const defaultSortingOrder = { order: 'ASCENDING', column: null }

export default {
  name: 'AutopilotSettings',
  components: { IcSearch, TableHeader, AiCampaignRows, TextInput, UpdateCampaignButton },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          value: 'name',
          label: 'Campaign Name',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'keyword_suggestions',
          label: 'Keyword Suggestions',
          sortable: false,
          sorting: null,
          position: 'right',
        },
        {
          value: 'autopilot',
          label: 'Autopilot',
          sortable: false,
          sorting: null,
          position: 'right',
        },
      ],
      sortingOrder: defaultSortingOrder,
      sortedTableData: [],
      touchedCampaignIds: [],
      query: null,
    }
  },
  computed: {
    autopilotLength() {
      return this.context.campaigns.filter(item => item.autopilot).length
    },
    campaignTableRows() {
      if (!this.query) return this.sortedTableData
      const rules = { shouldSort: true, isCaseSensitive: false, keys: ['name'] }
      const fuse = new Fuse(this.sortedTableData, rules)

      return fuse.search(this.query).map(result => result.item)
    },
  },
  mounted() {
    this.initializeCampaignSettings()
  },
  methods: {
    initializeCampaignSettings() {
      this.sortedTableData = structuredClone(this.context.campaigns).map(item => {
        item.type = 'campaign'
        return item
      })
    },
    touchCampaign(campaignId) {
      const touchedCampaign = this.touchedCampaignIds.find(item => item === campaignId)
      if (touchedCampaign) return
      this.touchedCampaignIds.push(campaignId)
    },
    updateStatus(campaignId, type, status) {
      if (![SWITCH_STATUS.ENABLED, SWITCH_STATUS.DISABLED].includes(status)) return

      const index = this.sortedTableData.findIndex(item => item.campaign_id === campaignId)

      // if keyword_suggestion is disabled, autopilot cannot be enabled
      if (!this.sortedTableData[index].keyword_suggestion && type === 'autopilot') {
        return
      }

      // if keyword_suggestion is disabled, autopilot should be disabled
      if (type === 'keyword_suggestion' && status === SWITCH_STATUS.DISABLED) {
        this.sortedTableData[index].autopilot = false
        this.sortedTableData[index].autopilot_enabled = {
          positive: false,
          negative: false,
        }
      }

      this.sortedTableData[index][type] = status === SWITCH_STATUS.ENABLED
      if (type === 'autopilot') {
        // activate or deactivate both positive and negative
        this.sortedTableData[index].autopilot_enabled = {
          positive: status === SWITCH_STATUS.ENABLED,
          negative: status === SWITCH_STATUS.ENABLED,
        }
      }
      this.touchCampaign(campaignId)
    },
    toggleAutopilotCategory(category, campaignId, status) {
      const campaign = this.sortedTableData.find(item => item.campaign_id === campaignId)
      campaign[category] = status
      this.touchCampaign(campaignId)
    },
    async save() {
      this.$refs.aiCampaignRows.expandedRow = null
      this.$emit('set-page-loading', true, 'CAMPAIGN_SETTINGS')
      try {
        const email = this.context.user.email
        const campaigns = this.sortedTableData
          .filter(item => this.touchedCampaignIds.includes(item.campaign_id))
          .map(item => ({
            campaign_id: item.campaign_id,
            keyword_suggestion: item.keyword_suggestion,
            autopilot_enabled: item.autopilot_enabled,
          }))

        const payload = { user_email: email, campaigns: campaigns }

        // GTM event for each campaign in payload
        campaigns.forEach(campaign => {
          if (campaign.autopilot_enabled.positive && campaign.autopilot_enabled.negative) {
            this.$gtm.trackEvent({
              event: 'kw_autopilot_enable',
              interaction_type: 'autopilot_toggle',
            })
          } else {
            this.$gtm.trackEvent({
              event: 'kw_autopilot_disable',
              interaction_type: 'autopilot_toggle',
            })
          }
        })

        await axios.post(`/search/site/${this.context.site_id}/keyword-suggestions/toggle`, payload)
        this.$emit('refetch-campaigns')
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'Changes have been saved successfully.',
            type: 'success',
          },
        })
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Your changes could not be saved.',
            type: 'error',
          },
        })
      } finally {
        this.query = null
        this.touchedCampaignIds = []
      }
    },
    cancel() {
      this.touchedCampaignIds = []
      this.query = null
      this.$refs.aiCampaignRows.expandedRow = null
      this.initializeCampaignSettings()
    },
    sortTable(data) {
      // Sorting Process
      const sortedData = sortingModule(data, this.sortedTableData)

      // Set sorted data
      this.sortedTableData = sortedData.data
      // Set sorting order
      this.sortingOrder = sortedData.order
      // Set sorting order in headers
      this.headers.find(header => header.value === sortedData.order.column).sorting = sortedData.order.order
    },
  },
}
</script>
