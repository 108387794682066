<template>
  <div>
    <div class="">
      <div id="my-scroll">
        <div v-if="settingsMenu">
          <router-link
            :to="{ name: 'dashboard' }"
            class="text-bb-text-secondary pt-4 px-6 flex flex-row gap-1 p1 items-center"
          >
            <ic-chevron />
            <p>Back</p>
          </router-link>
          <div class="p-6 flex flex-row items-center gap-4">
            <img
              src="/content/images/user-profile-icon.png"
              alt="Account settings"
            />
            <div class="h4 text-bb-text-default truncate">
              {{ userName }}
            </div>
          </div>
        </div>
        <div class="text-bb-mid-grey text-left p4 my-4 pl-6 uppercase">
          <p>
            {{ menuLevel }}
          </p>
        </div>
        <ul class="p2 overflow-visible mt-3 ml-5">
          <li
            v-for="(menuItem, index) in accessibleItems"
            :key="index"
          >
            <bb-sidebar-menu-link
              v-if="!menuItem.subMenu"
              :menu-item="menuItem"
            />
            <bb-sidebar-menu-group-link
              v-else
              :menu-item="menuItem"
            />
          </li>
        </ul>
        <div
          v-if="settingsMenu"
          class="fixed bottom-0 w-full mb-3 py-3 text-center text-bb-error cursor-pointer hover:bg-bb-red-50 transition-colors duration-300 ease-in-out"
        >
          Log Out
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BbSidebarMenuLink from '@/components/layout/Sidebar/SidebarMenuLink.vue'
import BbSidebarMenuGroupLink from '@/components/layout/Sidebar/SidebarMenuGroupLink.vue'
import IcSearch from '@/components/icon/ic-search'
import IcBars from '@/components/icon/ic-bars.vue'
import IcCampaign from '@/components/icon/ic-campaign.vue'
import IcChevron from 'vue-material-design-icons/ChevronLeft'
import IcAudit from '@/components/icon/brightbid/ic-audit.vue'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SidebarMenu',
  components: { BbSidebarMenuGroupLink, BbSidebarMenuLink, IcChevron },
  props: {
    isMenuOpened: {
      type: Boolean,
      default: true,
    },
    isPaddingLeft: {
      type: Boolean,
      default: true,
    },
    menuOpenedPaddingLeftBody: {
      type: String,
      default: '200px',
    },
    menuClosedPaddingLeftBody: {
      type: String,
      default: '0px',
    },
  },
  data() {
    return {
      isOpened: false,
      menuItems: {
        siteMenu: [
          {
            link: 'dashboard',
            name: 'Dashboard',
            icon: () => import('vue-material-design-icons/ViewDashboard'),
            standardUserAccess: true,
          },
          {
            link: 'campaigns',
            name: 'Campaigns',
            disabled: this.site,
            icon: IcCampaign,
            standardUserAccess: true,
          },
          {
            link: 'site:reports:audit',
            name: 'Audit',
            icon: IcAudit,
            disabled: this.site,
            standardUserAccess: true,
            beta: true,
          },
          {
            link: 'site:reports:datastudio',
            name: 'Report',
            disabled: this.site,
            icon: IcBars,
            standardUserAccess: true,
          },
          // {
          //   name: 'Shopping Ads',
          //   icon: () => import('vue-material-design-icons/Tag'),
          //   disabled: this.site,
          //   routeLevel: 'site:shopping',
          //   subMenu: [
          //     {
          //       name: 'Products',
          //       link: 'site:shopping:products',
          //       comingSoon: true,
          //     },
          //     {
          //       name: 'Feeds',
          //       link: 'site:shopping:feeds',
          //       comingSoon: true,
          //     },
          //     {
          //       name: 'Performance Labels',
          //       link: 'site:shopping:performance:labels',
          //       comingSoon: true,
          //     },
          //     {
          //       name: 'BrightBid CSS',
          //       link: 'site:shopping:brightbid:css',
          //       comingSoon: true,
          //     },
          //   ],
          // },
          {
            name: 'Search Ads',
            icon: IcSearch,
            disabled: this.site,
            routeLevel: 'site:search',
            subMenu: [
              {
                name: 'Keyword Suggestions',
                link: 'site:search:keyword:suggestions',
                standardUserAccess: true,
              },
              {
                name: 'Ad Generator',
                link: 'site:search:ad:generator',
                standardUserAccess: true,
              },
              {
                name: 'Competitor Monitoring',
                link: 'competitor-monitoring',
                standardUserAccess: true,
              },
              {
                name: 'CMS v2',
                link: 'competitor-monitoring-v2',
                adminProductionProtection: true,
              },
              {
                name: 'AI Activity Log',
                link: 'site:search:ai:activity:log',
                standardUserAccess: true,
              },
              // {
              //   name: 'Ads Generator',
              //   link: 'site:search:ads:generator',
              //   standardUserAccess: true,
              // },
              // {
              //   name: 'Keyword Intent',
              //   link: 'site:search:keyword:intent',
              //   standardUserAccess: true,
              // },
            ],
          },
          {
            name: 'Account Settings',
            icon: () => import('vue-material-design-icons/Cog'),
            disabled: this.site,
            routeLevel: 'site:settings',
            subMenu: [
              {
                name: 'Account Details',
                link: 'site:settings:site',
                standardUserAccess: true,
              },
              {
                name: 'Integrations',
                link: 'site:settings:integrations',
                standardUserAccess: false,
              },
              {
                name: 'Notifications',
                link: 'site:settings:notifications',
                comingSoon: true,
                standardUserAccess: false,
              },
            ],
          },
        ],
        accountMenu: [
          {
            link: 'settings:account',
            name: 'Account Settings',
            bbIcon: true,
            icon: 'ic-account',
            standardUserAccess: false,
          },
          {
            link: 'settings:organization',
            name: 'Organizations',
            bbIcon: true,
            icon: 'ic-corporate-fare',
            standardUserAccess: true,
          },
          {
            link: 'settings:user-management',
            name: 'Users',
            bbIcon: true,
            icon: 'ic-user',
            standardUserAccess: false,
          },
          {
            link: 'audit:external',
            name: 'External Audits',
            bbIcon: true,
            icon: 'ic-audit-outline',
            standardUserAccess: true,
            externalUserProtection: true, // Only visible to internal users
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      site: 'site/selectedSite',
      userName: 'auth/getUserName',
      isProductionEnvironment: 'hub/isProductionEnvironment',
    }),
    ...mapState('auth', ['user']),
    standardUser() {
      return this.user.role.name === 'standard'
    },
    adminUser() {
      return this.user.role.name === 'admin'
    },
    settingsMenu() {
      return this.$route.path.includes('console/settings')
    },
    menuLevel() {
      return this.settingsMenu ? 'Settings' : 'Overview'
    },
    menuLinks() {
      return this.settingsMenu ? this.menuItems.accountMenu : this.menuItems.siteMenu
    },
    accessibleItems() {
      if (!this.user) return
      return this.menuLinks
        .map(item => {
          if (item.subMenu) {
            // Handle sub-menu items
            const filteredSubMenu = item.subMenu.filter(subItem => {
              const isAccessibleInProduction =
                !this.isProductionEnvironment || this.adminUser || !subItem.adminProductionProtection
              const isAccessibleForExternalUser = !subItem.externalUserProtection || this.user.is_internal
              const isAccessibleForStandardUser = this.standardUser ? subItem.standardUserAccess : true

              return isAccessibleInProduction && isAccessibleForExternalUser && isAccessibleForStandardUser
            })

            return filteredSubMenu.length > 0
              ? {
                  ...item,
                  subMenu: filteredSubMenu,
                }
              : null
          } else {
            // Handle top-level items without sub-menu
            const isAccessibleInProduction =
              !this.isProductionEnvironment || this.adminUser || !item.adminProductionProtection
            const isAccessibleForExternalUser = !item.externalUserProtection || this.user.is_internal
            const isAccessibleForStandardUser = this.standardUser ? item.standardUserAccess : true

            return isAccessibleInProduction && isAccessibleForExternalUser && isAccessibleForStandardUser ? item : null
          }
        })
        .filter(item => item !== null)
    },
  },
  watch: {
    user(newValue) {
      if (!newValue) {
        this.$router.push({ name: 'signin' })
      }
    },
  },
  created() {
    if (!this.user) {
      this.$router.push({ name: 'signin' })
    }
  },
}
</script>

<style scoped lang="scss">
.logo-details {
  @apply relative flex items-center justify-center p-8 h-20 w-50;

  img {
    @apply hidden;
    @screen lg {
      @apply block cursor-pointer;
    }
  }
}

.admin-warning {
  @apply block m-auto text-bb-dull-red border border-bb-dull-red font-bold cursor-pointer px-2 rounded;
  width: max-content;
  &:hover {
    @apply opacity-75;
  }
}
</style>
