var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',[(_vm.tableRows.length === 0)?_c('tr',[_vm._m(0)]):_vm._l((_vm.tableRows),function(item,index){return _c('tr',{key:item.id,staticClass:"whitespace-no-wrap font-normal w-full text-sm",class:[
      _vm.hoveredRow === index ? 'bg-bb-background-purple-0' : 'bg-white',
      index % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
    ],on:{"mouseenter":function($event){_vm.hoveredRow = index},"mouseleave":function($event){_vm.hoveredRow = null}}},[_c('td',{staticClass:"px-4 py-2 whitespace-no-wrap sticky left-0 w-50",class:[
        _vm.hoveredRow === index ? 'bg-bb-background-purple-0' : 'bg-white',
        index % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
      ]},[_vm._v(" "+_vm._s(item.expression)+" ")]),_c('td',{staticClass:"px-4 text-right"},[_c('div',{staticClass:"flex items-center justify-end pr-4"},[_c('ic-delete',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoveredRow === index),expression:"hoveredRow === index"}],staticClass:"cursor-pointer",class:_vm.touchedExpressions.includes(item.id)
              ? 'text-bb-disabled-buttons pointer-events-none'
              : 'text-bb-disabled-gray',attrs:{"size":20},on:{"click":function($event){return _vm.removeExpression(item.id)}}})],1)])])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"mx-auto my-20 flex flex-col items-center justify-center"},[_c('p',{staticClass:"text-xl my-2"},[_vm._v("No expressions found...")])])])
}]

export { render, staticRenderFns }