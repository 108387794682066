<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.79688 7.37282C2.79688 7.11682 2.91688 6.87682 3.12488 6.72482L6.10887 4.54882C6.42887 4.31682 6.86887 4.34882 7.14887 4.62882L10.1729 7.65282L15.9329 3.46882C16.4529 3.08482 17.1969 3.46082 17.1969 4.11682C17.1969 4.37282 17.0769 4.61282 16.8689 4.76482L10.5489 9.34882C10.2289 9.58082 9.78887 9.54882 9.51687 9.26882L6.49287 6.24482L4.06888 8.02082C3.54088 8.40482 2.79688 8.02882 2.79688 7.37282ZM17.1969 12.9488C17.1969 12.5088 16.8369 12.1488 16.3969 12.1488H13.7169C13.5329 12.1488 13.3569 12.2128 13.2209 12.3248L10.1009 14.8208L5.67687 10.5408C5.39687 10.2688 4.97287 10.2368 4.66087 10.4608L3.14088 11.5408C2.92488 11.6928 2.79688 11.9408 2.79688 12.1968C2.79688 12.8448 3.53287 13.2288 4.06087 12.8448L5.03688 12.1488L9.49288 16.4608C9.78088 16.7408 10.2369 16.7648 10.5489 16.5088L13.9969 13.7488H16.3969C16.8369 13.7488 17.1969 13.3888 17.1969 12.9488Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcTwoLineChart',
  props: {
    size: {
      type: Number,
      default: 20,
    },
  },
}
</script>

<style scoped lang="scss"></style>
