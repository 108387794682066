<template>
  <div class="w-full px-6">
    <h4 class="h4 pb-8 pt-6">Exclusion Lists</h4>
    <div class="flex flex-row items-center justiy-center gap-2">
      <ic-no-account
        :size="20"
        class="text-bb-brand-purple"
      />
      <h5 class="h5">Competitor Exclusion List</h5>
    </div>
    <div class="grid grid-cols-3 gap-x-10 pt-2">
      <div class="flex flex-col">
        <p class="text-sm max-w-110">
          Prevent competitor names from appearing in your <span class="font-bold">positive</span> suggestions.
        </p>
        <text-input
          v-model="competitorExpressionInput"
          class="max-w-110 pt-4"
          input-type="text"
          input-name="competitor_terms"
          input-id="competitor_terms"
          placeholder="Add Competitor..."
          :error="competitorExpressionInputError"
          :error-message="competitorErrorMessage"
          @keydown.enter.prevent="handleExpressionInput(competitorExpressionInput, true)"
        >
          <template #suffix>
            <div class="h-10 w-24 absolute right-0 top-0 p-2">
              <div
                class="flex items-center justify-center text-bb-brand-purple duration-200 ease-linear font-medium rounded-full hover:text-bb-text-default hover:bg-neutral-100 cursor-pointer"
                @click="handleExpressionInput(competitorExpressionInput, true)"
              >
                <ic-plus :size="16" />
                <p>Add</p>
              </div>
            </div>
          </template>
        </text-input>
      </div>
      <!--TABLE FOR EXCLUDE COMPETITOR-->
      <div class="pl-20 col-span-2">
        <div class="border rounded-lg overflow-hidden">
          <div class="h-67 overflow-y-auto base-scrollbar">
            <table class="w-full">
              <table-header
                :headers="headers"
                :sorting-order="sortingOrder"
                class="sticky top-0 bg-white z-10"
                @sort-table="sortTable"
              />
              <exclusion-rows
                :table-rows="competitorTableData"
                :is-loading="isLoading"
                :touched-expressions="touchedExpressions"
                @add-expression="addExpression"
                @remove-expression="removeExpression"
                @update-expression="updateExpression"
              />
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row items-center justiy-center gap-2 pt-8">
      <ic-block
        :size="20"
        class="text-bb-brand-purple"
      />
      <h5 class="h5">Exclude from Positive Suggestions</h5>
    </div>
    <div class="grid grid-cols-3 gap-x-10 pt-2">
      <div class="flex flex-col">
        <p class="text-sm max-w-110">
          Prevent other expressions from appearing in your <span class="font-bold">positive</span> suggestions.
        </p>
        <text-input
          v-model="expressionInput"
          class="max-w-110 pt-4"
          input-type="text"
          input-name="brand_terms"
          input-id="brand_terms"
          placeholder="Add Expression..."
          :error="expressionInputError"
          :error-message="errorMessage"
          @keydown.enter.prevent="handleExpressionInput(expressionInput, false)"
        >
          <template #suffix>
            <div class="h-10 w-24 absolute right-0 top-0 p-2">
              <div
                class="flex items-center justify-center text-bb-brand-purple duration-200 ease-linear font-medium rounded-full hover:text-bb-text-default hover:bg-neutral-100 cursor-pointer"
                @click="handleExpressionInput(expressionInput, false)"
              >
                <ic-plus :size="16" />
                <p>Add</p>
              </div>
            </div>
          </template>
        </text-input>
      </div>
      <!--TABLE FOR EXCLUDE FROM POSITIVE-->
      <div class="pl-20 col-span-2">
        <div class="border rounded-lg overflow-hidden">
          <div class="h-67 overflow-y-auto base-scrollbar">
            <table class="w-full">
              <table-header
                :headers="headers"
                :sorting-order="sortingOrder"
                class="sticky top-0 bg-white z-10"
                @sort-table="sortTable"
              />
              <exclusion-rows
                :table-rows="generalTableData"
                :is-loading="isLoading"
                :touched-expressions="touchedExpressions"
                @add-expression="addExpression"
                @remove-expression="removeExpression"
                @update-expression="updateExpression"
              />
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row items-center justiy-center gap-2 pt-8">
      <ic-block
        :size="20"
        class="text-bb-brand-purple"
      />
      <h5 class="h5">Exclude from Negative and Pause Suggestions</h5>
    </div>
    <div class="grid grid-cols-3 gap-x-10 pt-2">
      <div class="flex flex-col">
        <p class="text-sm max-w-110">
          Prevent expressions from appearing in your <span class="font-bold">negative</span> and
          <span class="font-bold">pause</span> suggestions.
        </p>
        <text-input
          v-model="negExpressionInput"
          class="max-w-110 pt-4"
          input-type="text"
          input-name="negative_pause_terms"
          input-id="negative_pause_terms"
          placeholder="Add Expression..."
          :error="negExpressionInputError"
          :error-message="negExpressionErrorMessage"
          @keydown.enter.prevent="handleExpressionInput(negExpressionInput, false, true)"
        >
          <template #suffix>
            <div class="h-10 w-24 absolute right-0 top-0 p-2">
              <div
                class="flex items-center justify-center text-bb-brand-purple duration-200 ease-linear font-medium rounded-full hover:text-bb-text-default hover:bg-neutral-100 cursor-pointer"
                @click="handleExpressionInput(negExpressionInput, false, true)"
              >
                <ic-plus :size="16" />
                <p>Add</p>
              </div>
            </div>
          </template>
        </text-input>
      </div>
      <!--TABLE FOR NEGATIVE & PAUSE-->
      <div class="pl-20 col-span-2">
        <div class="border rounded-lg overflow-hidden">
          <div class="h-67 overflow-y-auto base-scrollbar">
            <table class="w-full">
              <table-header
                :headers="headers"
                :sorting-order="sortingOrder"
                class="sticky top-0 bg-white z-10"
                @sort-table="sortTable"
              />
              <exclusion-rows
                :table-rows="negtivePauseTableData"
                :is-loading="isLoading"
                :touched-expressions="touchedExpressions"
                @add-expression="addExpression"
                @remove-expression="removeExpression"
              />
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sortingModule from '@/utils/table/table-sorting'

import TableHeader from '@/components/table/TableHeader.vue'
import ExclusionRows from '@/components/table/table_rows/ExclusionRows.vue'
import Toast from '@/components/shared/Toast.vue'
import TextInput from '@/components/input/brightbid/TextInput.vue'

import IcBlock from '@/components/icon/brightbid/ic-block.vue'
import IcNoAccount from '@/components/icon/brightbid/ic-no-account.vue'
import IcPlus from '@/components/icon/ic-plus.vue'

const defaultSortingOrder = { order: 'ASCENDING', column: null }

export default {
  name: 'ExclusionList',
  components: { TableHeader, IcBlock, ExclusionRows, IcNoAccount, IcPlus, TextInput },
  props: {
    userEmail: {
      type: String,
      required: true,
    },
    siteId: {
      type: Number,
      required: true,
    },
    keywordExclusions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        {
          value: 'expression',
          label: 'Expression',
          sortable: true,
          sorting: null,
          position: 'left',
        },
      ],
      competitorTableData: [],
      generalTableData: [],
      negtivePauseTableData: [],
      touchedExpressions: [],
      sortingOrder: defaultSortingOrder,
      isLoading: false,
      expressionInputError: false,
      hasDuplicateKeyword: false,
      maximumLengthError: false,
      minimumLengthError: false,
      competitorExpressionInputError: false,
      negExpressionInputError: false,
      competitorExpressionInput: '',
      competitorErrorMessage: '',
      errorMessage: '',
      negExpressionErrorMessage: '',
      expressionInput: '',
      negExpressionInput: '',
    }
  },
  watch: {
    keywordExclusions: {
      immediate: true,
      handler(newVal) {
        this.competitorTableData = newVal.filter(item => item.is_competitor && !item.exclude_from_neg_pause)
        this.generalTableData = newVal.filter(item => !item.is_competitor && !item.exclude_from_neg_pause)
        this.negtivePauseTableData = newVal.filter(item => item.exclude_from_neg_pause)
      },
    },
  },
  methods: {
    sortTable(data) {
      const sortedData = sortingModule(data, this.sortedTableData)
      this.sortedTableData = sortedData.data
      this.sortingOrder = sortedData.order
      this.headers.find(header => header.value === sortedData.order.column).sorting = sortedData.order.order
    },
    resetErrors() {
      this.hasDuplicateKeyword = false
      this.maximumLengthError = false
      this.minimumLengthError = false
      this.expressionInputError = false
      this.errorMessage = ''
      this.competitorExpressionInputError = false
      this.competitorErrorMessage = ''
    },
    validateExpression(expression, isCompetitor, isNegativePause) {
      if (!expression) {
        return { valid: false, error: 'Expression cannot be empty.' }
      }
      if (expression.length < 2) {
        return { valid: false, error: 'Expression must be at least 2 characters long.' }
      }
      if (expression.length > 22) {
        return { valid: false, error: 'Expression cannot exceed 22 characters.' }
      }

      // This checks for duplicates in the same table
      const targetList = isNegativePause
        ? this.negtivePauseTableData
        : isCompetitor
          ? this.competitorTableData
          : this.generalTableData

      if (targetList.find(item => item.expression === expression)) {
        return { valid: false, error: 'This expression already exists in this list!' }
      }

      // This checks for duplicates in the other table
      if (!isNegativePause) {
        const otherList = isCompetitor ? this.generalTableData : this.competitorTableData
        if (otherList.find(item => item.expression === expression)) {
          return { valid: false, error: 'This expression is already excluded from positive suggestions!' }
        }
      }

      return { valid: true, error: '' }
    },
    handleExpressionInput(expressionInput, isCompetitor, isNegativePause = false) {
      this.resetErrors()
      const expression = expressionInput.trim()
      const validation = this.validateExpression(expression, isCompetitor, isNegativePause)

      if (!validation.valid) {
        if (isCompetitor) {
          this.competitorExpressionInputError = true
          this.competitorErrorMessage = validation.error
        } else if (isNegativePause) {
          this.negExpressionInputError = true
          this.negExpressionErrorMessage = validation.error
        } else {
          this.expressionInputError = true
          this.errorMessage = validation.error
        }
        return
      }

      if (isNegativePause) {
        this.addExpression(expression, false, this.negtivePauseTableData, true)
        this.negExpressionInput = ''
      } else if (isCompetitor) {
        this.addExpression(expression, true, this.competitorTableData, false)
        this.competitorExpressionInput = ''
      } else {
        this.addExpression(expression, false, this.generalTableData, false)
        this.expressionInput = ''
      }
    },
    async addExpression(keyword, isCompetitor, targetList, excludeFromNegPause = false) {
      try {
        this.isLoading = true
        const payload = {
          user_email: this.userEmail,
          expression: keyword,
          is_competitor: isCompetitor,
          exclude_from_neg_pause: excludeFromNegPause,
        }

        const { data: addedExpression } = await this.$http.post(
          `/search/site/${this.siteId}/keyword-suggestions/kws_exclusions`,
          payload,
        )

        // Ensure the keyword is added only to the correct list
        if (excludeFromNegPause) {
          this.negtivePauseTableData.push(addedExpression)
        } else if (isCompetitor) {
          this.competitorTableData.push(addedExpression)
        } else {
          this.generalTableData.push(addedExpression)
        }

        this.$emit('add-expression', addedExpression)
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to add expression. Please try again.',
            type: 'error',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async removeExpression(id) {
      try {
        this.touchedExpressions.push(id)
        await this.$http.delete(`/search/site/${this.siteId}/keyword-suggestions/kws_exclusions/${id}`)
        this.$emit('remove-expression', id)
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to remove expression. Please try again.',
            type: 'error',
          },
        })
      } finally {
        this.touchedExpressions = this.touchedExpressions.filter(item => item !== id)
      }
    },
    async updateExpression(id, isCompetitor) {
      try {
        this.touchedExpressions.push(id)
        const targetList = isCompetitor ? this.competitorTableData : this.generalTableData
        const keywordExclusion = targetList.find(item => item.id === id)
        const payload = {
          user_email: this.userEmail,
          expression: keywordExclusion.expression,
          is_competitor: isCompetitor,
        }
        const { data: updatedExpression } = await this.$http.put(
          `/search/site/${this.siteId}/keyword-suggestions/kws_exclusions/${id}`,
          payload,
        )
        this.$emit('update-expression', updatedExpression)
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to update expression. Please try again.',
            type: 'error',
          },
        })
      } finally {
        this.touchedExpressions = this.touchedExpressions.filter(item => item !== id)
      }
    },
  },
}
</script>
