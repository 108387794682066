<template>
  <page class="">
    <div
      v-if="isOverlayActive"
      name="no-click-overlay"
      class="absolute top-0 left-0 w-full h-full z-20"
      @click="handleEditStrategyModalState"
    ></div>
    <transition
      :name="`tab-transition-${direction}`"
      mode="out-in"
    >
      <ManageBudget v-if="pageState.managingBudget" />
    </transition>
    <transition
      :name="`tab-transition-${direction}`"
      mode="out-in"
    >
      <div v-if="!pageState.managingBudget">
        <page-section
          full-width
          bottom-border
          class="h-27 relative"
        >
          <div class="flex justify-between items-end">
            <page-title>
              <div class="flex items-center gap-3">
                <ic-campaign
                  :size="24"
                  class="text-bb-brand-purple"
                />
                Campaigns
              </div>
            </page-title>
            <div
              class="flex flex-row gap-x-1 items-center cursor-pointer"
              @click="openManageTab"
            >
              <MergeButtonRound button-type="tertiary">
                <template slot="left-icon">
                  <ic-dollar
                    :width="13"
                    :height="13"
                    class="text-bb-brand-purple font-medium"
                  ></ic-dollar>
                </template>
                Manage Budgets</MergeButtonRound
              >
              <div
                v-if="nbBudgetSuggestions"
                class="p-4 bg-indigo-500 rounded-full h-5 w-5 flex-col flex justify-center items-center"
              >
                <div class="text-neutral-100 text-sm font-bold uppercase leading-none tracking-wide">
                  {{ nbBudgetSuggestions }}
                </div>
              </div>
            </div>
          </div>

          <page-tabs
            class="absolute bottom-0 left-0 w-full"
            :selected-tab="selectedTab"
            :tabs="tabs"
          >
            <div
              v-for="tab in tabs"
              :key="tab.value"
              ref="tab"
              tabindex="0"
              class="cursor-pointer border-b-2 relative"
              :class="
                selectedTab === tab.value
                  ? 'border-bb-brand-purple text-bb-brand-purple font-medium'
                  : 'text-bb-text-default border-white hover:border-neutral-50 hover:text-bb-text-headers'
              "
              @click="selectTab(tab)"
            >
              <div class="px-6 py-2 flex gap-2">
                <component :is="tab.icon" />
                <p>
                  {{ tab.label }}
                </p>
              </div>
            </div>
            <div
              class="tab-underline"
              :style="underlineStyle"
            ></div>
          </page-tabs>
        </page-section>

        <!--MAIN CONTAINER-->
        <transition
          :name="`tab-transition-${direction}`"
          mode="out-in"
        >
          <div
            :key="selectedTab"
            class="main-container base-scrollbar bg-white"
          >
            <div
              v-if="isLoading"
              class="flex h-full items-center justify-center"
            >
              <brightbid-loader class="">Fetching campaign metrics</brightbid-loader>
            </div>
            <div
              v-if="!tableRows.length && !isLoading"
              class="w-full flex h-full items-center justify-center"
            >
              <div class="text-center space-y-2">
                <p class="text-2xl font-medium">Welcome to your campaigns!</p>
                <p>You don’t have any active campaign yet.</p>
                <img
                  src="/content/images/brightbid/no-active-campaigns.png"
                  alt="dashboard"
                />
              </div>
            </div>
            <div v-if="tableRows.length && !isLoading">
              <div class="m-6 space-y-6">
                <div class="flex items-center gap-3">
                  <p class="text-xl font-medium">
                    {{ tabTitle.title || tabTitle.label }}
                  </p>
                  <div
                    class="h-8 w-8 flex justify-center items-center rounded-full bg-bb-secondary-purple text-bb-brand-purple"
                  >
                    <p>{{ filteredCampaignByChannel.length }}</p>
                  </div>
                </div>
                <div class="w-full flex flex-wrap space-y-2 justify-between items-center">
                  <text-input
                    v-model="searchQuery"
                    class="w-64"
                    input-name="search-accounts"
                    input-id="search-accounts"
                    input-type="text"
                    placeholder="Search Campaigns..."
                    has-icon
                    padding-left="pl-10"
                  >
                    <template #prefix>
                      <div class="absolute z-10 h-10 p-2 text-bb-disabled-gray">
                        <IcSearch />
                      </div>
                    </template>
                  </text-input>
                  <div class="flex items-center gap-4 z-20">
                    <DropdownSelect
                      disabled
                      title="Last 30 days"
                      :options="[]"
                      :selection-value="{}"
                    >
                      <template #icon>
                        <ic-calendar-month :size="18" />
                      </template>
                    </DropdownSelect>
                    <DropdownSelect
                      title="Status"
                      :options="statusOptions"
                      :selection-value="selectedStatus"
                      @select-item="selectStatus"
                    >
                      <template #icon>
                        <ic-setting />
                      </template>
                    </DropdownSelect>
                    <MergeButtonRound
                      button-type="primary"
                      class="w-48"
                      disabled
                    >
                      <p class="flex items-center">
                        <ic-plus
                          :size="16"
                          class="pr-1"
                        />
                        <span>New Campaign</span>
                      </p>
                    </MergeButtonRound>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="!isLoading && tableRows.length"
              class="mx-6"
            >
              <bb-table
                ref="bbTable"
                class="border rounded-md h-full"
                :headers="headers"
                :data="campaignTableRows"
                :is-loading="false"
                :scroll-y="true"
                :sticky-header="true"
                :apply-initial-sort="false"
                max-table-height="calc(100vh - 430px)"
              >
                <template #rows="{ tableData }">
                  <campaign-rows
                    :data="tableData"
                    :editable="true"
                    :edit-active="editStrategyActive"
                    @editBiddingStrategy="editBiddingStrategy"
                    @closeEditStrategyModal="closeEditStrategyModal"
                    @selectBidStrategyMismatch="selectBidStrategyMismatch"
                  />
                </template>
              </bb-table>
            </div>
            <div class="z-10 h-16 sticky bottom-0 flex w-full bg-white">
              <UpdateCampaignButton
                :site-id="selectedSite.value"
                @set-page-loading="isLoading = true"
              ></UpdateCampaignButton>
            </div>
          </div>
        </transition>
      </div>
    </transition>

    <EditBidstrategyModal
      ref="editBidstrategyModalContainer"
      class="z-40"
      :selected-row="selectedRow"
      :show-modal="showEditStrategyModal"
      :is-loading="isFormLoading"
      :parent-element-position="parentElementPosition"
      :class="{ shake: shakeStrategyModal }"
      @submit-form="submitForm"
      @set-editing-state="setStrategyEditingState"
      @close="closeEditStrategyModal"
    >
    </EditBidstrategyModal>

    <BidStrategyMismatchModal
      :show-modal="showBidStrategyMismatchModal"
      :is-loading="isFormLoading"
      @close="showBidStrategyMismatchModal = false"
      @submit="resolveBidStrategyMismatch"
    >
    </BidStrategyMismatchModal>
  </page>
</template>
<script>
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'
import PageTabs from '@/components/shared/PageTabs.vue'
import IcCampaign from '@/components/icon/brightbid/ic-campaign.vue'
import GoogleSearchTag from '@/components/icon/brightbid/google-search-tag.vue'
import GoogleShoppingTag from '@/components/icon/brightbid/google-shopping-tag.vue'
import TextInput from '@/components/input/brightbid/TextInput.vue'
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import IcPlus from '@/components/icon/ic-plus.vue'
import BbTable from '@/components/table/BbTable.vue'
import TableHeader from '@/components/table/TableHeader.vue'
import CampaignRows from '@/components/table/table_rows/CampaignRows.vue'
import DropdownSelect from '@/components/input/brightbid/DropdownSelect.vue'
import IcSetting from '@/components/icon/brightbid/ic-setting.vue'
import IcSort from '@/components/icon/brightbid/ic-sort.vue'
import IcSearch from 'vue-material-design-icons/Magnify'
import DropdownArrow from '@/components/icon/brightbid/dropdown_arrow'
import Toast from '@/components/shared/Toast.vue'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'
import IcCalendarMonth from 'vue-material-design-icons/CalendarMonth'
import dayjs from 'dayjs'
import Fuse from 'fuse.js'
import { mapState } from 'vuex'
import IcDollar from '@/components/icon/ic-dollar.vue'
import IcKebab from '@/components/icon/ic-kebab.vue'
import ManageBudget from './ManageBudget.vue'
import { sort } from 'fast-sort'
import EditBidstrategyModal from '@/components/modals/EditBidstrategyModal'
import BidStrategyMismatchModal from '@/components/modals/BidStrategyMismatchModal'
import UpdateCampaignButton from '@/components/btn/UpdateCampaignButton.vue'

export default {
  name: 'SiteCampaigns',
  components: {
    IcSearch,
    PageTabs,
    PageSection,
    Page,
    IcCampaign,
    GoogleSearchTag,
    GoogleShoppingTag,
    TextInput,
    MergeButtonRound,
    IcPlus,
    BbTable,
    TableHeader,
    CampaignRows,
    DropdownSelect,
    IcSetting,
    IcSort,
    DropdownArrow,
    BrightbidLoader,
    IcCalendarMonth,
    IcDollar,
    IcKebab,
    ManageBudget,
    EditBidstrategyModal,
    BidStrategyMismatchModal,
    UpdateCampaignButton,
  },
  data() {
    return {
      underlineStyle: {
        left: '0px',
        width: '0px',
      },
      direction: '',
      isLoading: false,
      searchQuery: '',
      nbBudgetSuggestions: 0,
      tabs: [
        { label: 'All', value: 'all-campaigns', title: 'All Campaigns', index: 0 },
        { label: 'Google Search', value: 'google-search-tab', icon: GoogleSearchTag, index: 1 },
        { label: 'Google Shopping', value: 'google-shopping-tab', icon: GoogleShoppingTag, index: 2 },
      ],
      headers: [
        {
          value: 'campaign_name',
          label: 'Campaign Name',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'channel',
          label: 'Channel',
          sortable: false,
          sorting: null,
          position: 'left',
        },
        {
          value: 'bid_strategy_display_name',
          label: 'Bid Strategy',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'target',
          label: 'Target',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'budget',
          label: 'Budget',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'impressions',
          label: 'Impressions',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'cost',
          label: 'Cost',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'clicks',
          label: 'Clicks',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'avg_cpc',
          label: 'Avg. CPC',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'conversions',
          label: 'Conv.',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'conv_rate',
          label: 'Conv. Rate',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'cost_conv',
          label: 'Cost/Conv',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'ctr',
          label: 'CTR',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'roas',
          label: 'ROAS',
          sortable: true,
          sorting: null,
          position: 'right',
        },
        {
          value: 'action',
          label: 'Action',
          sortable: false,
          sorting: null,
          position: 'right',
        },
      ],
      tableRows: [],
      statusOptions: [
        { label: 'All', value: '' },
        { label: 'Live', value: 'live' },
        { label: 'Paused', value: 'paused' },
        { label: 'Draft', value: 'draft' },
      ],
      selectedTab: 'all-campaigns',
      selectedStatus: { label: '', value: '' },
      pageState: {
        managingBudget: false,
      },
      showEditStrategyModal: false,
      selectedRow: null,
      isFormLoading: false,
      showBidStrategyMismatchModal: false,
      parentElementPosition: { top: 0, right: 0 },
      editStrategyActive: false,
      shakeStrategyModal: false,
      isOverlayActive: false,
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    tabTitle() {
      return this.tabs.find(item => item.value === this.selectedTab)
    },
    campaignTableRows() {
      // QUERY FILTER
      let tableRows = this.tableRows
      if (this.searchQuery) {
        const rules = {
          shouldSort: true,
          isCaseSensitive: false,
          keys: ['campaign_name'],
        }
        const fuse = new Fuse(this.tableRows, rules)
        tableRows = fuse.search(this.searchQuery).map(result => result.item)
      }

      tableRows = tableRows.filter(item => {
        if (!this.selectedStatus.value) return item
        return item.status === this.selectedStatus.value
      })

      // TABS FILTER
      switch (this.selectedTab) {
        case 'google-search-tab':
          return tableRows.filter(item => item.channel === 'search')
        case 'google-shopping-tab':
          return tableRows.filter(item => item.channel === 'shopping')
        default:
          return tableRows
      }
    },
    filteredCampaignByChannel() {
      switch (this.selectedTab) {
        case 'google-search-tab':
          return this.tableRows.filter(item => item.channel === 'search')
        case 'google-shopping-tab':
          return this.tableRows.filter(item => item.channel === 'shopping')
        default:
          return this.tableRows
      }
    },
    currentRouteState() {
      return this.$route.query.state
    },
  },
  watch: {
    async selectedSite() {
      this.tableRows = await this.getSiteCampaigns()
      this.selectedTab = 'all-campaigns'
      this.selectedStatus = { label: '', value: '' }
      this.searchQuery = ''
    },
    selectedTab() {
      this.updateUnderline()
    },
    currentRouteState(newValue) {
      this.pageState.managingBudget = newValue === 'managingBudget'
    },
  },
  async mounted() {
    this.$nextTick(() => {
      this.updateUnderline()
    })
    this.isLoading = true
    this.pageState.managingBudget = this.currentRouteState === 'managingBudget'

    const [siteCampaigns, budgetSuggestionsCount] = await Promise.all([
      this.getSiteCampaigns(),
      this.getBudgetSuggestionsCount(),
    ])
    this.tableRows = siteCampaigns
    this.nbBudgetSuggestions = budgetSuggestionsCount

    this.isLoading = false
  },
  methods: {
    async getSiteCampaigns() {
      try {
        const today = dayjs()
        // start date by default is 30 days before today
        const startDate = today.subtract(30, 'day').format('YYYY-MM-DD')
        const endDate = today.format('YYYY-MM-DD')
        const { data } = await this.$http.get(
          `/common/campaign?start_date=${startDate}&end_date=${endDate}&site_id=${this.selectedSite.value}`,
        )
        return this.applyDefaultOrder(data)
      } catch (error) {
        // return empty array to show empty state
        return []
      }
    },

    updateUnderline() {
      this.$nextTick(() => {
        const selectedTabElement = this.$refs.tab.find(tab => tab.classList.contains('border-bb-brand-purple'))
        if (selectedTabElement) {
          const { offsetLeft, offsetWidth } = selectedTabElement
          this.underlineStyle = {
            left: `${offsetLeft}px`,
            width: `${offsetWidth}px`,
          }
        }
      })
    },
    selectTab(tab) {
      const newIndex = this.tabs.findIndex(t => t.value === tab.value)
      const currentIndex = this.tabs.findIndex(t => t.value === this.selectedTab)

      this.direction = newIndex > currentIndex ? 'left' : 'right'

      setTimeout(() => {
        this.selectedTab = tab.value
        this.updateUnderline()
      }, 100) // Match this timeout to your transition duration
    },

    async getBudgetSuggestionsCount() {
      try {
        const { data } = await this.$http.get(`/common/site/${this.selectedSite.value}/budget-suggestion/count`)
        return data.count
      } catch (error) {
        return null
      }
    },
    selectStatus(status) {
      if (status.value === this.selectedStatus.value || status.value === '') {
        this.selectedStatus = { label: '', value: '' }
        return
      } else {
        this.selectedStatus = status
      }

      // reset the sorting
      this.$refs.bbTable.setSortingOrder()
    },
    openManageTab() {
      this.pageState.managingBudget = true
      this.$trackGTMEvent('budget_suggestion_view')
      this.$router.push({ query: { ...this.$route.query, state: 'managingBudget' } })
    },
    applyDefaultOrder(campaigns) {
      /*
      List live campaigns on the top of the campaign list
      then sort by cost in descending order and
      then by campaign name in ascending order
      */
      const statusOrder = { live: 3, draft: 2, paused: 1 }
      const data = sort(campaigns).by([
        { desc: u => statusOrder[u.status] || 0 },
        { desc: u => u.cost },
        { asc: u => u.campaign_name },
      ])
      return data
    },
    async setRowData(data) {
      try {
        this.tableRows.find((item, index) => {
          if (item.id === this.selectedRow.id) {
            this.tableRows[index].bid_strategy_display_name = data.bid_strategy_label
            this.tableRows[index].target_value = data.target_value
            this.tableRows[index].target_type = data.target_type_raw
            this.tableRows[index].is_brightbid_bid_strategy = data.is_brightbid_bid_strategy
            this.tableRows[index].google_bid_strategy_name = data.google_bid_strategy_name
          }
        })
      } catch (e) {
        console.log(e)
        this.tableRows = await this.getSiteCampaigns()
      }
    },
    editBiddingStrategy({ item, position }) {
      this.selectedRow = item
      this.parentElementPosition = position
      this.showEditStrategyModal = true
      this.isOverlayActive = true
    },
    async submitForm(data) {
      const payload = {
        is_brightbid_bid_strategy: data.is_brightbid_bid_strategy,
        google_bid_strategy_name: data.google_bid_strategy_name,
        target_type: data.target_type,
        target_value: data.target_value,
      }
      try {
        this.isFormLoading = true
        const response = await this.$http.patch(
          `/search/site/${this.selectedSite.value}/campaign/${this.selectedRow.id}/bidding-strategy`,
          payload,
        )
        await this.setRowData(data)
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: response.data.message || 'Bidding strategy updated successfully',
            type: 'success',
          },
        })
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: e?.response?.message || 'Failed to save and push the strategy.',
            type: 'error',
          },
        })
      } finally {
        this.isFormLoading = false
        this.closeEditStrategyModal()
      }
    },
    closeEditStrategyModal() {
      this.selectedRow = null
      this.isOverlayActive = false
      this.editStrategyActive = false
      this.showEditStrategyModal = false
    },
    selectBidStrategyMismatch(item) {
      this.selectedRow = item
      this.showBidStrategyMismatchModal = true
    },
    async resolveBidStrategyMismatch(payload) {
      try {
        this.isFormLoading = true
        const response = await this.$http.post(
          `/search/site/${this.selectedSite.value}/campaign/${this.selectedRow.id}/bidding-strategy/mismatch`,
          payload,
        )
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: response.data.message,
            type: 'success',
          },
        })
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: e?.response?.data?.message ?? 'Something went wrong',
            type: 'error',
          },
        })
      } finally {
        this.isFormLoading = false
        this.showBidStrategyMismatchModal = false
      }
      this.tableRows = await this.getSiteCampaigns()
    },
    setStrategyEditingState(state) {
      this.editStrategyActive = state
    },
    handleEditStrategyModalState() {
      if (this.editStrategyActive) {
        this.shakeStrategyModal = true
        setTimeout(() => (this.shakeStrategyModal = false), 1000)
      } else {
        this.closeEditStrategyModal()
      }
    },
  },
}
</script>

<style scoped>
.main-container {
  overflow-y: auto;
  height: calc(100vh - 213px);
}

.tab-underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: #6366fa;
  transition:
    left 0.3s ease,
    width 0.3s ease;
}
.tab-transition-left-enter-active,
.tab-transition-left-leave-active {
  transition: transform 0.3s ease;
}

.tab-transition-left-enter {
  transform: translateX(100%);
}

.tab-transition-left-leave {
  transform: translateX(-100%);
}

.tab-transition-right-enter-active,
.tab-transition-right-leave-active {
  transition: transform 0.3s ease;
}

.tab-transition-right-enter {
  transform: translateX(-100%);
}

.tab-transition-right-leave {
  transform: translateX(100%);
}
</style>
